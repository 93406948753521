import React, {useEffect, useState} from 'react'
import ShareholderCompany from './ShareholderCompany'
import styles from './Shareholder.module.scss'
import ShareholderPerson from './ShareholderPerson'
import FpRadio from "../UI/FpRadio";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getValidationPercent } from "../../Helpers/ComponentValidator";
import * as Api from '../../utils/api'
import { isEmpty } from "../../store/tools";
import { useParams } from "react-router-dom";
import useDialogStore from "../../contexts/dialogStore";
import useShareholderStore from "../../contexts/shareholderContext";
import useAlertContext from "../../contexts/alertContext";
import useStore from '../../contexts/context';
import FpSelectButton from "../UI/FpSelectButton";
import {
    SHAREHOLDER_COMPANY_VALIDATION_RULES,
    SHAREHOLDER_PERSON_VALIDATION_RULES
} from "../../validation/formValidation";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: "40px"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Shareholder = () => {
    let { slug } = useParams();
    const classes = useStyles();
    const [form, setForm] = useState({});
    const [isOwnership, setIsOwnership] = useState(true);
    const { setDialogState } = useDialogStore();
    const { setAlert } = useAlertContext();
    const { setShareholderState } = useShareholderStore()
    const { shareholderExpandeds: { shareholderExpanded, setShareholderExpanded }, setLoader } = useStore();
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const calculateAllOwnerships = (shareholders) => {
        let sum = 0
        shareholders.forEach(({ ownership }) => (
          sum += +ownership
        ))
        setIsOwnership(sum < 100);
    }

    const calculateOwnershipsSum = (shareholderId, currentOwnership) => {
        let sum = +currentOwnership ?? 0;
        const otherOwnerships = form.filter(({ id }) => id !== shareholderId);
        otherOwnerships.forEach(({ ownership }) => {
            sum += +ownership ?? 0;
        });
        if (sum > 100) {
            return false;
        } else {
            if (sum === 100) {
                setIsOwnership(false);
            } else {
                setIsOwnership(true);
            }
            const copyForm = [...form];
            const index = form.findIndex(({ id }) => id === shareholderId);
            const currentShareholder = form.find(({ id }) => id === shareholderId);
            const newCurrentShareholder = {
                ...currentShareholder,
                ownership: currentOwnership
            };
            copyForm.splice(index, 1, newCurrentShareholder);
            setForm(copyForm);
            return true;
        }
    };

    function getShareholderFullName(item) {
        const copyForm = [...form];
        const currentShareholder = form.find(({ id }) => id === item.id);

        const index = form.findIndex(({ id }) => id === item.id);
        if (item && item.kind === 'corporate') {
            const newCurrentShareholder = {
                ...currentShareholder,
                name: item.name ?? 'Shareholder'
            };
            copyForm.splice(index, 1, newCurrentShareholder);
        } else {
            let firstName = item?.firstName;
            let lastName = item?.lastName;
            const newCurrentShareholder = {
                ...currentShareholder,
                firstName: firstName ?? '',
                lastName: lastName ?? ''
            };
            copyForm.splice(index, 1, newCurrentShareholder);
        }
        setForm(copyForm)
    }

    function isCurrentFormEmpty(item) {
        let isCorporate = (item.kind === "corporate")
        let currentItem = isCorporate ? item?.corporate : item?.individual
        let currentValidationRules = isCorporate ? SHAREHOLDER_COMPANY_VALIDATION_RULES : SHAREHOLDER_PERSON_VALIDATION_RULES

        let progress = getValidationPercent(currentItem, currentValidationRules);
        return (progress <= 0 || !progress)
    }

    const prepareToToggleShareholderType = (item) => {
        setDisabled(true);
        setLoader(true);
        Api.instance
          .post('/api/application/shareholder/change-type', {
              shareholder_id: item.id
          })
          .then(() => {
              Api.instance.get(`/api/application/shareholder?form_url=${slug}`)
                .then(({ data: { items } }) => {
                    const changedItem = items.find(({id}) => id === item.id)
                    setShareholderState((prev) => {
                        return prev.map((elem) => {
                            if (elem.id === item.id) {
                                return changedItem
                            } else {
                                return elem
                            }
                        })
                    })
                    setForm(items);
                }).finally(() => setLoader(false))

          })
          .finally(() => {
              setDisabled(false);
          })
          .catch(() => setLoader(false))
    };

    const removeShareholder = async (id, i) => {
        setLoader(true);
        let filteredShareholderExpanded = {...shareholderExpanded}
        delete filteredShareholderExpanded[`shareholder_${i}`]
        setShareholderExpanded(filteredShareholderExpanded)
        await Api.instance.delete(`/api/application/shareholder/${id}`)
          .then(async () => {
            await getShareholders('remove', id);
              setAlert({
                  open: true,
                  variant: 'success',
                  message: 'Shareholder was deleted successfully',
              });
        })
          .catch(() => {
              setAlert({
                  open: true,
                  variant: 'error',
              });
          })
          .finally(() => setLoader(false));
    };

    const requestToRemoveShareholder = (item, i) => {
        setDialogState((prev) => ({
            ...prev,
            open: true,
            buttonText: 'Delete',
            description: `Do you want to remove ${getShareholderFullName(
              item
            )} from shareholders list?`,
            handleEvent: () => removeShareholder(item.id, i)
        }));
    };

    function needToDrawComponent() {
        let needToDraw = false;
        if (form && !!form.length) {
            form.map((item) => {
                if (!isEmpty(item)) {
                    needToDraw = true;
                }
            });
        }
        return needToDraw;
    }

    const getShareholders = async (type = 'add', id) => {
        const {
            data: { items }
        } = await Api.instance.get(`/api/application/shareholder?form_url=${slug}`);
        const shareholderData = items.map((item) => {
            return form.find((el) => el.id === item.id) ?? item;
        })
        if (type === 'add') {
            const newItem = items[items.length - 1]
            setShareholderState((prev) => ([...prev, {
                ...newItem,
                isValid: false,
            }]))
        } else {
            setShareholderState((prev) => {
                return prev.filter((elem) => elem.id !== id)
            })
        }

        setForm(shareholderData);
    };

    const createUbo = async (id, kind) => {
        await Api.instance.post('/api/application/ubo', {
            shareholder_id: id,
            type: kind
        }).catch(() => {
            setAlert({
                open: true,
                variant: 'error',
            });
        });
    };

    const addShareholder = (kind) => {
        setLoading(true)
        Api.instance
          .post(`/api/application/shareholder`, {
              form_url: slug,
              kind,
          })
          .then(async ({ data: { item } }) => {
              await createUbo(item?.kindId, kind);
              await getShareholders();
              setAlert({
                  open: true,
                  variant: 'success',
                  message: 'Shareholder was added successfully',
              });
          })
          .catch(() => {
              setAlert({
                  open: true,
                  variant: 'error',
              });
          })
          .finally(() => {
              setLoading(false)
          })
        
    };

    const changeShareholderType = (item) => {
        setDialogState((prev) => ({
            ...prev,
            open: true,
            buttonText: 'OK',
            description: 'Are you sure you want to change shareholder type?',
            handleEvent: () => prepareToToggleShareholderType(item)
        }));
    };

    useEffect(() => {
        if (slug) {
            Api.instance.get(`/api/application/shareholder?form_url=${slug}`)
              .then(({ data: { items } }) => {
                  setForm(items);
                  setShareholderState(items)
                  calculateAllOwnerships(items);
              })
        }
    }, [slug])

    return (
        <div className={styles.shareholder}>
            <div id={'shareholder'} className={classes.root}>
                {
                    needToDrawComponent() && form.map((item, i) => {
                        return (
                            <Accordion defaultExpanded={!isCurrentFormEmpty(item)} expanded={!!shareholderExpanded[`shareholder_${i}`]} key={i} id={`shareholder` + i}>
                                <AccordionSummary
                                    aria-controls={`panel${i}a-content`}
                                    id={`panel${i}a-header`}
                                    expandIcon={<ExpandMoreIcon style={{color: "#151724"}}/>}
                                    onClick={e => {
                                       setShareholderExpanded((prev) => ({
                                           ...prev,
                                           [`shareholder_${i}`]: !prev[`shareholder_${i}`],
                                       }))
                                        if (e.target.tagName === 'SPAN') {
                                            e.stopPropagation();
                                            return false;
                                        }
                                    }}
                                >
                                    <div className={styles.select_box}>
                                        <div className={styles.left_side}>
                                            <img src="/images/shareHolders.svg" alt=""/>
                                            <p>
                                                {item.kind === 'corporate' ? (
                                                  <p>{item.name ?? 'Shareholder'}</p>
                                                ) : (
                                                  <p>
                                                      {(item.firstName + item.lastName).length > 0
                                                        ? item.firstName + ' ' + item.lastName
                                                        : 'Shareholder'}
                                                  </p>
                                                )}
                                            </p>
                                        </div>
                                        <div className={styles.right_side}>
                                            {form?.length > 1 && <span onClick={e => {
                                                requestToRemoveShareholder(item, i);
                                                e.stopPropagation();
                                            }} className={styles.accordion_title_text}>Delete</span>}
                                            <p className={styles.percent}>
                                                {item?.ownership ?? '0'}%
                                            </p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                {item ? (
                                    <AccordionDetails>
                                        <div>
                                            <div className="radio_component">
                                                <div className={styles.radio_wrapper}>
                                                    <FpRadio
                                                        options={[{private_individual: 'Private_individual'}, {corporation: 'corporation'}]}
                                                        title={["Private Individual", "Corporate"]}
                                                        className="container"
                                                        needToChangeValueImmediately={false}
                                                        defaultValue={(item.kind === "individual") ? 'private_individual' : 'corporation'}
                                                        disabled={disabled}
                                                        onClick={function (e) {
                                                            e.preventDefault();
                                                            changeShareholderType(item);
                                                        }}
                                                        name={`corporation_${i}`}
                                                    />
                                                </div>
                                            </div>
                                            {item.kind === "individual" ? (
                                                <ShareholderPerson
                                                    calculateOwnershipsSum={calculateOwnershipsSum}
                                                    getFullName={getShareholderFullName}
                                                    index={i}
                                                    shareholderId={item?.id}
                                                />
                                            ) : (
                                                <ShareholderCompany
                                                    calculateOwnershipsSum={calculateOwnershipsSum}
                                                    getFullName={getShareholderFullName}
                                                    index={i}
                                                    shareholderId={item?.id}
                                                />
                                            )}
                                        </div>
                                    </AccordionDetails>
                                ) : null
                            }
                        </Accordion>
                    )})}
            </div>
            {isOwnership && (
              <FpSelectButton
                loading={loading}
                onClick={(type) => addShareholder(type)}
                placeholder="Choose shareholder type"
                data={[
                    {
                        value: 'corporate',
                        label: 'Corporate Shareholder'
                    },
                    {
                        value: 'individual',
                        label: 'Private Individual Shareholder'
                    }
                ]}
              />
            )}
        </div>
    )
}

export default Shareholder
