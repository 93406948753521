import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import "./fonts/Montserrat/Montserrat.css"
import "./fonts/Inter/inter.css"
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import * as Sentry from "@sentry/react";
import { instance } from "./Helpers/matomo"
import { BrowserTracing } from "@sentry/tracing";
import config from './config/server'

Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <MatomoProvider value={instance}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MatomoProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
