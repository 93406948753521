import React from "react";
import { Field } from "react-final-form";
import FpNumberInput from "./FpInput/FpNumberInput";
import styles from "./style.module.scss";

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Field {...this.props}>
        {({ input, meta }) => (
          <div className={this.props.className}>
            <FpNumberInput
              type={this.props.type}
              onWheel={(e) => e.target.blur()}
              placeholder={this.props.placeholder}
              defaultValue={input.value}
              onChange={input.onChange}
              name={input.name}
              className={
                meta.error && meta.touched && styles.validator_error_style
              }
            />
            {meta.error && meta.touched && (
              <span className={styles.validator_text}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }
}

export default NumberInput;
