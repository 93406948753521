import React from "react";
import { Field } from "react-final-form";
import FpDatePicker from "./FpDatePicker/FpDatePicker";
import styles from "./style.module.scss";

class Date extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Field {...this.props}>
        {({ input, meta }) => (

          <div>
            <FpDatePicker
              reactForm={true}
              defaultValue={input.value}
              onChange={input.onChange}
              maxDate={this.props.maxDate}
              minDate={this.props.minDate}
              name={input.name}
              placeholder={this.props.placeholder}
              className={
                meta.error && meta.touched && styles.validator_error_style
              }
            />
            {meta.error && meta.touched && (
              <span className={styles.validator_text}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }
}

export default Date;
