export default {
    baseUrl: process.env.REACT_APP_BACKEND_URL ?? 'http://localhost',
    sentryDsn: process.env.REACT_APP_SENTRY_DSN,
    jwt: {
        secret: process.env.WEB_SECRET,
        expires: '10m'
    },
    companiesHouse: {
        apiKey: process.env.COMPANIES_HOUSE_API_KEY,
        apiUrl: 'https://api.company-information.service.gov.uk'
    }
}