export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export function isRequired(value) {
  return value ? undefined : 'Required';
}

export function isValidEmail(value) {
  return /\S+@\S+\.\S+/.test(value) ? undefined : 'Invalid Email';
}

export const isEmailValidation = async (valuesChanged, setError, changeData, values) => {
  if (isValidEmail(valuesChanged.email)) {
    return setError((prev) => ({
      ...prev,
      email: isValidEmail(valuesChanged.email)
    }));
  }
  setError({})
  await changeData(values);
};

export const getValidationRules = (values, rules) => {
  const validationRules = {
    pepDescription: values?.pep === "yes" ? "required:string" : "nullable",
    loundertDescription: values?.loundert === "yes" ? "required:string" : "nullable",
    crimDescription: values?.criminal === "yes" ? "required:string" : "nullable",
    sanctionDescription: values?.sanction === "yes" ? "required:string" : "nullable",
  };

  return { ...validationRules, ...rules };
};
