import React from "react";
import {FormSpy} from "react-final-form";
import diff from "object-diff";
import styles from "../styles/autoSave.module.scss";

class AutoSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            submitting: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {values, valuesDidChanged} = this.props;
        if (valuesDidChanged) {
            valuesDidChanged(values);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.save, this.props.debounce);
    }

    save = async () => {
        //TODO What if we cancel current promise to execute the new one?
        if (this.promise) {
            await this.promise;
        }
        const {values, save} = this.props;

        // This diff step is totally optional
        if (this.state && this.state.values && values) {
            const difference = diff(this.state.values, values);
            if (Object.keys(difference).length) {
                this.setState({submitting: true, values});
                this.promise = save(values, difference);
                await this.promise;
                delete this.promise;
                this.setState({submitting: false});
            }
        }
    };

    render() {
        // This component doesn't have to render anything, but it can render
        // submitting state.
        return (
            this.state.submitting && (
                <div className={styles.submitting}>
                    <div className={styles.loading}/>
                </div>
            )
        );
    }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default (props) => (
    <FormSpy {...props} subscription={{values: true}} component={AutoSave}/>
);
