import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router-dom";
import * as Api from "../../utils/api";
import ManagerComponent from "./ManagerContent";
import useDialogStore from "../../contexts/dialogStore";
import useManagerStore from "../../contexts/managerContext";
import useStore from "../../contexts/context"
import Loader from "../Loader/Loader"
import useAlertContext from "../../contexts/alertContext";

const Management = () => {
  const {managerExpandeds: {managerExpanded, setManagerExpanded}} = useStore()
  let { slug } = useParams();
  const [managers, setManagers] = useState([{}]);
  const { setAlert } = useAlertContext();
  const { setDialogState } = useDialogStore()
  const { setManagerState } = useManagerStore()
  const [loading, setLoading] = useState(false)

  function getManagerFullName(item) {
    const copyManagers = [...managers];

    const currentManager = managers.find(({ id }) => id === item.id);
    const index = managers.findIndex(({ id }) => id === item.id);

    let firstName = item?.firstName;
    let lastName = item?.lastName;

    const newCurrentManager = {
      ...currentManager,
      firstName: firstName ?? '',
      lastName: lastName ?? ''
    };

    copyManagers.splice(index, 1, newCurrentManager);

    setManagers(copyManagers)
  }

  const getManagerData = async (type = 'add', id) => {
    const {
      data: { items }
    } = await Api.instance.get(`/api/application/manager?form_url=${slug}`);
    if (type === 'add') {
      const newItem = items[items.length - 1]
      setManagerState((prev) => ([...prev, {
        ...newItem,
        isValid: false,
      }]))
    } else {
      setManagerState((prev) => {
        return prev.filter((elem) => elem.id !== id)
      })
    }
    setManagers(items);
  }

  const addManager = async () => {
    setLoading(true)
    await Api.instance
      .post('/api/application/manager', { form_url: slug })
      .then(async () => {
        await getManagerData();
        setAlert({
          open: true,
          variant: 'success',
          message: 'Manager was added successfully',
        });
      })
      .catch(() => {
        setAlert({
          open: true,
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const removeManager = async (id, index) => {
    let filteredManagerExpanded = {...managerExpanded}
    delete filteredManagerExpanded[`manager_${index}`]
    setManagerExpanded(filteredManagerExpanded)
    await Api.instance.delete(`/api/application/manager/${id}`)
      .then(async () => {
        await getManagerData('remove', id);
        setAlert({
          open: true,
          variant: 'success',
          message: 'Manager was deleted successfully',
        });
    })
    .catch(() => {
      setAlert({
        open: true,
        variant: 'error',
      });
    });
  };

  useEffect(() => {
    if (slug) {
      Api.instance.get(`/api/application/manager?form_url=${slug}`)
        .then(({ data: { items } }) => {
          setManagerState(items)
          setManagers(items)
        })
    }
  }, [slug]);

  return (
    <div className={styles.managment} id="management_a">
      <h2>3. Management</h2>
      <span>
        Please provide information about the applicant’s board of directors /
        management board
      </span>
      <div id={"manager"}>
        {managers && managers?.map((item, index) => (
          <Accordion key={index} expanded={!!managerExpanded[`manager_${index}`]} id={"management"+index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{color: "#151724"}} />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              onClick={e => {
                setManagerExpanded((prev) => ({
                    ...prev,
                    [`manager_${index}`]: !prev[`manager_${index}`],
                }))
             }}
            >
              <div className={styles.select_box}>
                <div className={styles.left_side}>
                  <img src="/images/director_icon.svg" alt="" />
                  <p>
                    {(item.firstName + item.lastName).length > 0
                      ? item.firstName + ' ' + item.lastName
                      : 'Director'}
                  </p>
                </div>
                {managers.length > 1 && (
                  <span
                    onClick={(e) => {
                      setDialogState((prev) => ({
                        ...prev,
                        open: true,
                        description: `Do you want to remove ${getManagerFullName(
                          item
                        )} from managers list?`,
                        buttonText: 'Delete',
                        handleEvent: () => removeManager(item.id, index)
                      }));
                      e.stopPropagation();
                    }}
                    className={styles.accordion_title_text}>
                    Delete
                  </span>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ManagerComponent
                getFullName={getManagerFullName}
                index={index}
                managerId={item?.id}
                copyDirector={true}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <button onClick={addManager} className={styles.add_manager} disabled={loading}>
        {loading && <span className={styles.loading_span}>
          <Loader />
        </span>}
        <span>Add new Director</span>
      </button>
    </div>
  );
};

export default Management;
