import React, {useEffect, useState} from "react";
import AutoSave from "../../Helpers/AutoSave";
import FpCheck from "../UI/FpCheck/FpCheck";
import styles from "./style.module.scss";
import FpSelect from "../UI/FpSelect/FpSelect";
import { Form } from "react-final-form";
import FpFile from "../UI/FpFile/FpFile";
import { useParams } from "react-router-dom";
import Input from "../UI/Input";
import Country from "../UI/Country";
import DatePicker from "../UI/Date";
import PhoneCode from "../UI/PhoneCode";
import Radio from "../UI/Radio";
import TextArea from "../UI/TextArea";
import useStore from "../../contexts/context";
import * as Api from '../../utils/api'
import { getCurrentDate, getDate18YearsAgo } from "../../utils/DateUtils";
import useManagerStore from "../../contexts/managerContext";
import { MANAGEMENT_VALIDATION_RULES } from "../../validation/formValidation";
import { getValidationPercent } from "../../Helpers/ComponentValidator";
import { isRequired, isEmailValidation, getValidationRules } from "../../utils/validation";
import {validEmail} from "../../Helpers/FormValidator"
import {getDataWithDuplicates} from "../../utils/getCountriesArray";
const ManagerComponent = ({
  managerId,
  index,
  copyDirector = false,
  getFullName = () => {}
}) => {
  let formActualValues = {};
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let { slug } = useParams();
  const { readySubmit, setReadySubmit, setLoader } = useStore();
  const [error, setError] = useState({})
  const [form, setForm] = useState({});
  const [shareholderData, setShareholderData] = useState([]);
  const { setManagerState } = useManagerStore()


  function formValuesDidChanged(formValues) {
    formActualValues = formValues;
  };

  const changeManagerData = async () => {
    await Api.BackgroundPut(`/api/application/manager/${managerId}`, {
      ...formActualValues
    })
  };

  const setManagerName = (valuesChanged) => {
    if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('firstName') ||
      valuesChanged.hasOwnProperty('lastName')
    ) {

      setManagerState((prev) => {
        return prev.map((elem) => {
          if (elem?.id === managerId) {
            return {
              ...elem,
              firstName: formActualValues?.firstName,
              lastName: formActualValues?.lastName,
            }
          } else {
            return elem
          }
        })
      })

      getFullName({
        firstName: formActualValues?.firstName,
        lastName: formActualValues?.lastName,
        id: managerId
      });
    }
  };

  const AutoSaver = async (values, valuesChanged) => {
    const validateRules = getValidationRules(values, MANAGEMENT_VALIDATION_RULES);
    await sleep(1000);
    setManagerName(valuesChanged);

    if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('email')
    ) {
     return await isEmailValidation(valuesChanged, setError, changeManagerData)
    };

    if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('taxResidencyCountry')
    ) {
      const autoFillValue = getDataWithDuplicates.find(element => element.iso3 === valuesChanged?.['taxResidencyCountry']);
      const actualValues = { ...values, phoneCode: autoFillValue.value }

      setForm(actualValues);

      return await changeManagerData();
    };

    setManagerState((prev) => {
      return prev.map((item) => {
        if (item?.id === managerId) {
          return {
            ...item,
            isValid: getValidationPercent(values, validateRules) === 100
          }
        } else {
          return item
        }
      })
    })
    await changeManagerData()
  };

  const deleteFilesFromShareholder = async (isChecked) => {
    await Api.instance.delete(`/api/uploads/share-files/3/${managerId}`)
      .then(() => {
        setForm({ ...form, checked: isChecked, uploads: [] });
      })
  };

  const getShareholders = async (isChecked) => {
    if (isChecked) {
      const {
        data: { items }
      } = await Api.instance.get(`/api/application/individuals?form_url=${slug}`);
      setShareholderData(items);
      setForm({ ...form, checked: isChecked });
    } else {
      await deleteFilesFromShareholder(isChecked);
    }
  };

  const getShareHolderById = async (shareholderId) => {
    const {
      data: { item }
    } = await Api.instance.get(`/api/application/shareholder/${shareholderId}`);
    return item;
  };

  const getManagersFiles = async (values) => {
    await Api.instance.get(`/api/get-files/3/${managerId}`)
      .then(({ data }) => {
        setForm({ ...values, uploads: data?.items });
      })
  };

  const createFilesFromShareholder = (shareholderId) => {
    const data = {
      form_url: slug,
      shareholderId,
      typeId: managerId,
      type: 3,
    };

    return Api.instance.post('/api/uploads/share-files', { ...data })
  };

  const handleSelectChange = async (target) => {
    setLoader(true);
    const shareholder = await getShareHolderById(target?.value);
    const actualValues = {
      ...formActualValues,
      ...shareholder,
      crim: shareholder.criminal,
      samePerson: target.value
    }
    await createFilesFromShareholder(target?.value)
      .catch(() => setLoader(false));
    await getManagersFiles(actualValues)
      .finally(() => setLoader(false))

  };

  const changeFormCheck = async (isChecked) => {
    await getShareholders(isChecked);
  };

  const getShareholderName = (firstName, lastName) => {
    const first_name = firstName ?? '';
    const last_name = lastName ?? '';
    if ((first_name + last_name).length) {
      return first_name + ' ' + last_name;
    } else {
      return 'Shareholder';
    }
  };

  const onSubmit = async () => {
    await setReadySubmit({ ...readySubmit, director: true });
  };

  useEffect(() => {
    if (managerId) {
      Api.instance
        .get(`/api/application/manager/${managerId}`)
        .then(({ data: { item } }) => {
          setForm(item);
        });
    }
  }, [managerId]);

  return (
    <div>
      <Form
        className="hide"
        onSubmit={onSubmit}
        initialValues={form}
        subscription={{}}
      >
        {({ handleSubmit}) => (
            <form className={"submittableForm"} onSubmit={handleSubmit}>
            <button
              style={{ display: "none" }}
              className={`doSubmit`}
              type="submit"
            >
              Submit
            </button>
            <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged}/>
              {copyDirector && (
                <FpCheck
                  title="One of the Shareholders as a director / manager"
                  checked={form && form?.checked}
                  onChange={(e, checked) => changeFormCheck(checked)}
                  className={styles.checkbox}
                />
              )}
            {form?.checked && (
              <FpSelect
                title="Shareholders List *"
                placeholder="Choose Shareholder"
                onChange={(target) => handleSelectChange(target, index)}
                data={shareholderData?.map((item) => ({
                  value: item.id,
                  label: getShareholderName(item.firstName, item.lastName)
                }))}
              />
            )}

            <div className={styles.justify_center_section}>
              <Input
                placeholder="First name *"
                name="firstName"
                validate={isRequired}
              />
              <Input
                placeholder="Last name *"
                name="lastName"
                validate={isRequired}
              />
            </div>

            <div className={styles.justify_center_section_person}>
              <Country
                placeholder="Country of birth *"
                name="cob"
                validate={isRequired}
              />
              <DatePicker
                placeholder="Date of birth *"
                maxDate={getDate18YearsAgo()}
                name="dob"
                id="director-dob"
                validate={isRequired}
              />
            </div>

            <div className={styles.justify_center_section}>
              <Country
                placeholder="Citizenship *"
                name="citizenship"
                validate={isRequired}
              />
              <Input
                placeholder="Passport/ID card No *"
                name="passportId"
                validate={isRequired}
              />
            </div>

            <div className={styles.justify_center_section}>
              <Country
                placeholder="Issuing country *"
                name="issCountry"
                validate={isRequired}
              />
              <Input
                placeholder="Issuing authority *"
                name="issAuthority"
                validate={isRequired}
              />
            </div>

            <div className={styles.justify_center_section}>
              <DatePicker
                placeholder="Date of issue *"
                maxDate={getCurrentDate()}
                name="issDate"
                validate={isRequired}
              />
              <DatePicker
                placeholder="Valid till *"
                minDate={getCurrentDate()}
                name="validTill"
                validate={isRequired}
              />
            </div>

            <div className={styles.justify_center_section_person}>
              <Country
                placeholder="Tax residence country *"
                name="taxResidencyCountry"
                validate={isRequired}
              />
              <Input placeholder="Taxpayer ID (if available)" name="taxpayerId" />
            </div>

            <div className={styles.display_flex_section}>
              <Input
                placeholder="Residence address *"
                name="residenceAddr"
                validate={isRequired}
              />
              <Input
                placeholder="Postcode *"
                name="postcode"
                validate={isRequired}
              />
            </div>

            <div className={styles.display_flex_reverse_section}>
              <PhoneCode
                placeholder="Phone code *"
                name="phoneCode"
                validate={isRequired}
              />
              <Input
                placeholder="Phone number *"
                name="contactPhone"
                type="number"
                validate={isRequired}
              />
            </div>

            <Input
              placeholder="Email *"
              name="email"
              validate={validEmail}
              error={error}
            />

            <div className="radio_component">
              <span className={styles.section_title}>
                Is the Member of Management Board a Politically Exposed Person,
                Relative and Close Associate? *
              </span>
              <Radio name="pep" validate={isRequired} onClick={e => {
                setForm({...formActualValues, pep: e.target.value})}
              } />
              {form?.pep === "yes" && (
                <TextArea
                  placeholder="Please explain"
                  name="pepDescription"
                  validate={isRequired}
                />
              )}
            </div>

            <div className="radio_component">
              <span className={styles.section_title}>
                Is the Member of Management Board related with the US? *
              </span>
              <Radio name="relationUS" validate={isRequired} onClick={e => {
                setForm({...formActualValues, relationUS: e.target.value})
                            }} />
              {form?.relationUS === "yes" && (
                <Input
                  placeholder="Tax identification No"
                  name="taxIdNumber"
                />
              )}
            </div>

            <div className="radio_component">
              <span className={styles.section_title}>
                Is the Member of Management Board involved in money laundering
                activities any time in the past? *
              </span>
              <Radio name="loundert" validate={isRequired} onClick={e => {
                setForm({...formActualValues, loundert: e.target.value})
               }} />
              {form?.loundert === "yes" && (
                <TextArea
                  placeholder="Please explain"
                  name="loundertDescription"
                  validate={isRequired}
                />
              )}
            </div>

            <div className="radio_component">
              <span className={styles.section_title}>
                Is the Member of Management Board subject to criminal prosecution?
                *
              </span>
              <Radio name="crim" validate={isRequired} onClick={e => {
                setForm({...formActualValues, crim: e.target.value})
              }} />
              {form?.crim === "yes" && (
                <TextArea
                  placeholder="Please explain"
                  name="crimDescription"
                  validate={isRequired}
                />
              )}
            </div>

            <div className="radio_component">
              <span className={styles.section_title}>
                Is the Member of Management Board subject to any sanctions by
                official or financial regulatory bodies? *
              </span>
              <Radio name="sanction" validate={isRequired} onClick={e => {
                setForm({...formActualValues, sanction: e.target.value})
               }} />
              {form?.sanction === "yes" && (
                <TextArea
                  placeholder="Please explain"
                  name="sanctionDescription"
                  validate={isRequired}
                />
              )}
            </div>

            <FpFile
              title={"Drop your documents here!"}
              uploads={form?.uploads}
              componentId={managerId}
              type="manager"
              upload_type={[
                'Register of Directors',
                'Passport or ID card',
                'Proof of residential address (utility bill, bank statement, bank reference letter)'
              ]}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

export default ManagerComponent;
