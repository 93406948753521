import React, {useEffect, useMemo, useState} from "react";
import styles from "./style.module.scss";
import * as Api from "../../../utils/api";
import {useParams} from "react-router-dom";
import FormValidator from "../../../Helpers/FormValidator";
import useStore from "../../../contexts/context";
import Input from "../../UI/Input";
import {Form} from "react-final-form";
import AutoSave from "../../../Helpers/AutoSave";
import {SETTLEMENT_INFO_VALIDATION_RULES} from "../../../validation/formValidation";

const SettlementInfo = () => {
  let {slug} = useParams();
  const [form, setForm] = useState({
    form_url: slug,
    bank_name: "",
    swift_code: "",
    iban: ""
  });
  const [data] = Api.Get('/api/application/settlement/' + slug);

  const {settlementInfo} = useStore();
  const {readySubmit, setReadySubmit} = useStore();

  useMemo(() => {
    if (data && data.item && data.item.settlement) {
      data.item.settlement['form_url'] = slug;
      setForm(data.item.settlement);
    }
  }, [data]);

  const progressBar = (values) => {
    const [warnings, progress] = FormValidator(values, SETTLEMENT_INFO_VALIDATION_RULES)

    settlementInfo.setSettlementPercent(progress)
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const required = value => (value ? undefined : "Required");

  const AutoSaver = async (values) => {
    await Api.BackgroundPost('/api/application/settlement', values)
    progressBar(values)

    await sleep(1000);
  };

  const onSubmit = async values => {
    await setReadySubmit({...readySubmit, acquiring: true})
  };

  return (
    <div className={styles.settlement_info} id="settlementInfo">
      <h3 id={'settlement_info'}>1.4. Settlement Information</h3>
      <Form
        onSubmit={onSubmit}
        initialValues={form}
        subscription={{}}
        className="hide"
      >
        {({handleSubmit}) => (
            <form className={"submittableForm"} onSubmit={handleSubmit}>
            <button style={{display: 'none'}} className={`doSubmit`} type="submit">
              Submit
            </button>
            <AutoSave debounce={1000} save={AutoSaver}/>
            <Input placeholder={`Bank name *`} name={`bank_name`} validate={required}/>
            <label className="row">
              <Input placeholder={`SWIFT code *`} name={`swift_code`} validate={required}/>
              <Input placeholder={`IBAN *`} name={`iban`} validate={required}/>
            </label>
            <Input placeholder={`Accounts in other Banks (name, country)`} name={`accInOtherBanks`}/>
            <Input placeholder={`Acquiring in other Banks/institutions (name, country)`} name={`acquirInOtherBanks`}/>
          </form>
        )}
      </Form>
    </div>
  )
}

export default SettlementInfo;
