import React from 'react';
import './App.css';
import Home from './pages/index';
import { Store } from "./contexts/context"
import { ManagerStore } from "./contexts/managerContext"
import { ShareholderStore } from './contexts/shareholderContext';
import { DialogStore } from './contexts/dialogStore';
import { Alert } from './contexts/alertContext';
import Loader from "./components/Loader";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import animation from './animations/404';
import SuccessSubmit from './components/UI/SuccessSubmit';
import BasicAlerts from "./components/UI/Alert";
import SuccessLiveness from "./components/UI/SuccessLiveness";
import FailedLiveness from "./components/UI/FailedLiveness";

function App() {
  return (
    <Store>
        <ManagerStore>
            <ShareholderStore>
                <DialogStore>
                    <Alert>
                        <Router>
                            <Switch>
                                <Route path="/key/:slug">
                                    <Home />
                                </Route>
                                <Route path="/success">
                                    <SuccessSubmit />
                                </Route>
                                <Route path="/verification-success">
                                    <SuccessLiveness />
                                </Route>
                                <Route path="/verification-failed">
                                    <FailedLiveness />
                                </Route>
                                <Route path="/" exact>
                                    <Player
                                        autoplay
                                        loop
                                        src={animation}
                                        style={{ height: '100vh', margin: 0, padding: 0 }}
                                    >
                                    </Player>
                                </Route>
                            </Switch>
                        </Router>
                        <BasicAlerts />
                        <Loader />
                    </Alert>
                </DialogStore>
            </ShareholderStore>
        </ManagerStore>
    </Store>
  );
}

export default App;
