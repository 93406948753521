import React, { useEffect } from 'react';
import styles from './Dialog.module.scss';

export const Dialog = ({ dialogState, setDialogState, initialState }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.dialog}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.description}>{dialogState.description}</div>
        <div className={styles.buttons_wrapper}>
          <button onClick={() => setDialogState(initialState)}>
            {dialogState.cancelText ?? 'Cancel'}
          </button>
          {dialogState.buttonText && (
            <button
              onClick={() => {
                dialogState.handleEvent();
                setDialogState(initialState);
              }}>
              {dialogState.buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
