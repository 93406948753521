import React, { useEffect, useState } from "react";
import FpFile from "../UI/FpFile/FpFile";
import styles from "./Shareholder.module.scss";
import BeneficialOwner from "./BeneficialOwner";
import Input from "../UI/Input";
import { Field, Form } from "react-final-form";
import Country from "../UI/Country";
import DatePicker from "../UI/Date";
import Radio from "../UI/Radio";
import PhoneCode from "../UI/PhoneCode";
import AutoSave from "../../Helpers/AutoSave";
import useStore from "../../contexts/context";
import { getCurrentDate, getDate18YearsAgo } from "../../utils/DateUtils";
import * as Api from "../../utils/api";
import useDialogStore from "../../contexts/dialogStore";
import useShareholderStore from "../../contexts/shareholderContext";
import { SHAREHOLDER_PERSON_VALIDATION_RULES } from "../../validation/formValidation";
import { getValidationPercent } from "../../Helpers/ComponentValidator";
import { isRequired, isEmailValidation, getValidationRules } from "../../utils/validation";
import { getDataWithDuplicates } from "../../utils/getCountriesArray";
import {validEmail} from "../../Helpers/FormValidator"
import Loader from "../Loader/Loader"
import useAlertContext from "../../contexts/alertContext";

const ShareholderPerson = ({
 index,
 shareholderId,
 calculateOwnershipsSum = () => {},
 getFullName = () => {},
}) => {
  let formActualValues = {};
  const { readySubmit, setReadySubmit } = useStore();
  const [form, setForm] = useState({});
  const [error, setError] = useState({})
  const [ubo, setUbo] = useState([])
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { setDialogState } = useDialogStore();
  const { setAlert } = useAlertContext();
  const { setShareholderState } = useShareholderStore()
  const [loading, setLoading] = useState(false)

  function formValuesDidChanged(formValues) {
    formActualValues = formValues;
  }

  const approveToDelete = (id) => {
    Api.instance.delete(`/api/application/ubo/${id}`)
      .then(async () => {
        const {
          data: { items }
        } = await Api.instance.get(
          `/api/application/ubo?shareholder_id=${shareholderId}&type=individual`
        );
        setUbo(items);
        setShareholderState((prev) => {
          return prev.map((elem) => {
            if (elem.id === shareholderId) {
              const banficialOwner = elem.beneficial_owner.filter((el) => el.id !== id)
              return {
                ...elem,
                beneficial_owner: banficialOwner
              }
            } else {
              return elem
            }
          })
        })
        setAlert({
          open: true,
          variant: 'success',
          message: 'UBO was deleted successfully',
        });

      })
      .catch(() => {
        setAlert({
          open: true,
          variant: 'error',
        });
    });
  };

  const deleteBeneficialOwner = (elem) => {
    setDialogState((prev) => ({
      ...prev,
      open: true,
      description: `Do you want to remove ${
        elem?.firstName && elem?.lastName
          ? elem?.firstName + ' ' + elem?.lastName + '?'
          : 'Beneficial Owner?'
      }`,
      buttonText: 'Delete',
      handleEvent: () => approveToDelete(elem?.id)
    }));
  };

  const setShareholderName = (valuesChanged) => {
    if (
      Object.keys(valuesChanged).length <= 2 &&
      (valuesChanged.hasOwnProperty('firstName') ||
      valuesChanged.hasOwnProperty('lastName'))
    ) {
      setShareholderState((prev) => {
        return prev.map((elem) => {
          if (elem?.id === shareholderId) {
            return {
              ...elem,
              firstName: formActualValues?.firstName,
              lastName: formActualValues?.lastName,
            }
          } else {
            return elem
          }
        })
      })
      getFullName({
        kind: 'individual',
        firstName: formActualValues?.firstName,
        lastName: formActualValues?.lastName,
        id: shareholderId
      });
    }
  }

  const addNewBeneficialOwner = async () => {
    setLoading(true)
    Api.instance
      .post('/api/application/ubo', {
        shareholder_id: form?.id,
        type: 'individual',
      })
      .then(async () => {
        const {
          data: { items }
        } = await Api.instance.get(
          `/api/application/ubo?shareholder_id=${shareholderId}&type=individual`
        );
        const newItem = items[items?.length - 1]
        setShareholderState((prev) => {
          return prev.map((elem) => {
            if (elem.id === shareholderId) {
              return {
                ...elem,
                beneficial_owner: elem?.beneficial_owner?.length
                  ?  [...elem?.beneficial_owner, newItem]
                  : [newItem]
              }
            } else {
              return elem
            }
          })
        })
        setUbo(items);
        setAlert({
          open: true,
          variant: 'success',
          message: 'UBO was added successfully',
        });
      })
      .catch(() => {
        setAlert({
          open: true,
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const changeShareholderData = async (values) => {
    await Api.instance.put(`api/application/shareholder/${shareholderId}`, {
      ...values
    })
  };

  const displayInvalidOwnershipAlert = () => {
    setDialogState((prev) => ({
      ...prev,
      cancelText: 'OK',
      open: true,
      description: `Summary ownership of all shareholders can not be greater than 100%.`
    }));
    setForm({
      ...formActualValues,
      ownership: '0'
    });
  };

  const validateOwnership = async (values) => {
    if (
      !calculateOwnershipsSum(shareholderId, formActualValues?.ownership) ||
      +formActualValues?.ownership > 100
    ) {
      displayInvalidOwnershipAlert();
    } else {
      await changeShareholderData(values)
    }
  };

  const AutoSaver = async (values, valuesChanged) => {
    const validateRules = getValidationRules(values, SHAREHOLDER_PERSON_VALIDATION_RULES);
    await sleep(1000);
    if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('ownership')
    ) {
      await validateOwnership(values)
    } else if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('email')
    ) {
      await isEmailValidation(valuesChanged, setError, changeShareholderData, values)
    } else if (
      Object.keys(valuesChanged).length > 0 &&
      valuesChanged.hasOwnProperty('taxResidencyCountry')
    ) {
      const autoFillValue = getDataWithDuplicates.find(element => element.iso3 === valuesChanged?.['taxResidencyCountry']);
      const actualValues = { ...values, phoneCode: autoFillValue?.value }

      setForm(actualValues);

      await changeShareholderData(actualValues);

    } else {
      setShareholderName(valuesChanged)
      setShareholderState((prev) => {
        return prev.map((item) => {
          if (item?.id === shareholderId) {
            return {
              ...item,
              isValid: getValidationPercent(values, validateRules) === 100
            }
          } else {
            return item
          }
        })
      })
      await changeShareholderData(values)
    }
  };

  const onSubmit = async () => {
    await setReadySubmit({ ...readySubmit, shareholder: true });
  };

  useEffect(() => {
    if (shareholderId) {
      Api.instance.get(`api/application/shareholder/${shareholderId}`)
        .then(({ data: { item }  }) => setForm(item));
      Api.instance.get(`/api/application/ubo?shareholder_id=${shareholderId}&type=individual`)
        .then(({ data: { items }  }) => {
          setShareholderState((prev) => {
            return prev.map((elem) => {
              if (elem.id === shareholderId) {
                return {
                  ...elem,
                  beneficial_owner: items
                }
              } else {
                return elem
              }
            })
          })
          setUbo(items)
        })
    }
  }, [shareholderId]);

  return (
    <div>
      <div className="hide">
        <Form
          onSubmit={onSubmit}
          initialValues={form}
          subscription={{}}
          className="hide"
        >
          {({ handleSubmit}) => (
            <form className={"submittableForm"} onSubmit={handleSubmit} autoComplete="off">
              <button
                  style={{display: "none"}}
                  className={`doSubmit`}
                  type="submit"
              >
                Submit
              </button>
              <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged}/>
              <div className={styles.justify_center_section}>
                <Input
                  name={`firstName`}
                  placeholder={`First name *`}
                  validate={isRequired}
                />
                <Input
                  name={`lastName`}
                  placeholder={`Last name *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.justify_center_section_person}>
                <Country
                  name={`cob`}
                  placeholder={`Country of birth *`}
                  validate={isRequired}
                />
                <DatePicker
                  name={`dob`}
                  maxDate={getDate18YearsAgo()}
                  placeholder={`Date of birth *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.justify_center_section}>
                <Country
                  name={`citizenship`}
                  placeholder={`Citizenship *`}
                  validate={isRequired}
                />
                <Input
                  name={`passportId`}
                  placeholder={`Passport/ID card No *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.justify_center_section}>
                <Country
                  name={`issCountry`}
                  placeholder={`Issuing country *`}
                  validate={isRequired}
                />
                <Input
                  name={`issAuthority`}
                  placeholder={`Issuing authority *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.justify_center_section}>
                <DatePicker
                  name={`issDate`}
                  maxDate={getCurrentDate()}
                  placeholder={`Date of issue *`}
                  validate={isRequired}
                />
                <DatePicker
                  name={`validTill`}
                  minDate={getCurrentDate()}
                  placeholder={`Valid till *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.justify_center_section}>
                <Country
                  name={`taxResidencyCountry`}
                  placeholder={`Tax residence country *`}
                  validate={isRequired}
                />
                <Input
                  name={`taxpayerId`}
                  placeholder={`Taxpayer ID (if available)`}
                />
              </div>

              <div className={styles.display_flex_section}>
                <Input
                  name={`residenceAddr`}
                  placeholder={`Residence address *`}
                  validate={isRequired}
                />
                <Input
                  name={`postcode`}
                  placeholder={`Postcode *`}
                  validate={isRequired}
                />
              </div>

              <div className={styles.display_flex_reverse_section}>
                <PhoneCode
                  name={`phoneCode`}
                  placeholder={`Phоne code *`}
                  validate={isRequired}
                />
                <Input
                  name={`contactPhone`}
                  placeholder={`Phone number *`}
                  type="number"
                  validate={isRequired}
                />
              </div>

              <Input
                name={`email`}
                placeholder={`Email *`}
                validate={validEmail}
                error={error}
              />

              <div>
                {form?.sof?.includes("Other (please specify)") && (
                  <Input
                    name="otherSof"
                    placeholder={`Other sources of funds`}
                  />
                )}
                <span className={styles.section_title_top}>
                  Directly owns capital shares/shares with the voting right
                </span>
                <Input
                  name={`ownership`}
                  placeholder={`Shareholder %`}
                  className={`ownership_input`}
                  validate={isRequired}
                  type="number"
                />
              </div>

              <div className="radio_component">
                <span className={styles.section_title}>
                  Is this person a Politically Exposed Person, Relative and
                  Close Associate? *
                </span>
                <Radio
                    className="container"
                    name={`pep`}
                    validate={isRequired}
                    onClick={(e) => setForm({...formActualValues, pep: e.target.value})}
                />
              </div>
              <div className="grid-1 mb-1">
                {form?.pep === "yes" && (
                  <Input
                    placeholder="Please explain"
                    name="pepDescription"
                    validate={isRequired}
                  />
                )}
              </div>

              <div className="radio_component">
                <span className={styles.section_title}>
                  Is this person related with the US? *
                </span>
                <Radio
                  className="container"
                  name={`relationUS`}
                  validate={isRequired}
                  onClick={(e) => setForm({...formActualValues, relationUS: e.target.value})}
                />
              </div>

              <div className="grid-1">
                {form?.relationUS === "yes" && (
                  <Input
                    name={`taxIdNumber`}
                    placeholder={`Tax identification No`}
                  />
                )}
              </div>

              <div className="radio_component">
                <span className={styles.section_title_top}>
                  Was Shareholder involved in money laundering activities any time in the past? *
                </span>
                <Radio
                  className="container"
                  name={`loundert`}
                  validate={isRequired}
                  onClick={(e) => setForm({...formActualValues, loundert: e.target.value})}
                />
              </div>

              <div className="grid-1">
                {form?.loundert === "yes" && (
                  <Input
                    name="loundertDescription"
                    placeholder="Please explain"
                    validate={isRequired}
                  />
                )}
              </div>

              <div className="radio_component">
                <span
                  style={{ width: "fit-content" }}
                  className={styles.section_title_top}
                >
                  Was Shareholder a subject to criminal prosecution? *
                </span>
                <Radio
                  className="container"
                  name={`criminal`}
                  validate={isRequired}
                  onClick={(e) => setForm({...formActualValues, criminal: e.target.value})}
                />
              </div>

              <div className="grid-1">
                {form?.criminal === "yes" && (
                  <Input
                    name="crimDescription"
                    placeholder="Please explain"
                    validate={isRequired}
                  />
                )}
              </div>

              <div className="radio_component">
                <span className={styles.section_title_top}>
                  Has Shareholder been sanctioned by any official or financial regulatory bodies? *
                </span>
                <Radio
                  className="container"
                  name={`sanction`}
                  validate={isRequired}
                  onClick={(e) => setForm({...formActualValues, sanction: e.target.value})}
                />
              </div>
              <div className="grid-1">
                {form?.sanction === "yes" && (
                  <Input
                    name="sanctionDescription"
                    placeholder="Please explain"
                    validate={isRequired}
                  />
                )}
              </div>
            </form>
          )}
        </Form>
        <FpFile
          name="personUploads"
          uploads={form?.uploads}
          type='shareholder'
          componentId={shareholderId}
          title={"Drop your documents here!"}
          upload_type={[
            "Register of Shareholders / Register of Partners",
            "Passport or ID card",
            "Proof of residential address (utility bill, bank statement, bank reference letter)",
          ]}
        />
        {ubo?.map((row, pos) => {
          return (
            <BeneficialOwner
              key={pos}
              index={index}
              pos={pos}
              uboId={row?.id}
              deleteUBO={() => deleteBeneficialOwner(row, pos)}
              isMany={ubo?.length > 1}
              copyShareholder
            />
          );
        })}
        <button
          type={"button"}
          onClick={addNewBeneficialOwner}
          className={styles.add_owner}
          disabled={loading}
        >
          {loading && <span className={styles.loading_span}>
            <Loader />
          </span>}
          <span>Add Additional Benefical Owner</span>
        </button>
      </div>
    </div>
  );
};

export default ShareholderPerson;
