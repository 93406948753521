import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import useAlertContext from "../../contexts/alertContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackbar: {
    left: "64px",
    background: 'white',
    transform: "translateX(0) !important"
  }
}));

export default function BasicAlerts() {
  const classes = useStyles();
  const { alert: {
    variant,
    message,
    open
  },
    initialState,
    setAlert,
  } = useAlertContext();

  const MuiAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = () => {
    setAlert(initialState);
  };


  return (
    <div className={classes.root}>
      <Snackbar className={classes.snackbar} open={open} autoHideDuration={8000} onClose={handleClose}>
        {variant === 'error' ? (
          <MuiAlert onClose={handleClose} variant="outlined" severity="error">
            Something went wrong. Please try again. If this error persists, please contact us here
            <a href="https://fmpay.co.uk/support/" target="_blank"> https://fmpay.co.uk/support</a>
          </MuiAlert>
        ) : (
          <MuiAlert onClose={handleClose} variant="outlined" severity="success">{message}</MuiAlert>
        )}
      </Snackbar>
    </div>
  );
};
