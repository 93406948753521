import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import useStore from '../contexts/context';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 878,
    color: '#fff',
  },
}));

const Loader = () =>  {
  const classes = useStyles();
  const {loader} = useStore();
  return (
    <Backdrop className={classes.backdrop} open={loader}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
};

export default Loader;
