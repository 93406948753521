import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import FpFile from "../../UI/FpFile/FpFile";
import * as Api from "../../../utils/api";
import { useParams } from "react-router-dom";
import FormValidator, { validEmail, validWebsite } from "../../../Helpers/FormValidator";
import FpCheck from "../../UI/FpCheck/FpCheck";
import useStore from "../../../contexts/context";
import { Form } from "react-final-form";
import AutoSave from "../../../Helpers/AutoSave";
import Country from "../../UI/Country";
import Input from "../../UI/Input";
import DatePicker from "../../UI/Date";
import Radio from "../../UI/Radio";
import TextArea from "../../UI/TextArea";
import PhoneCode from "../../UI/PhoneCode";
import moment from "moment/moment";
import {
  COMPANY_DETAILS_ADDITIONAL_VALIDATION_RULES,
  COMPANY_DETAILS_INITIAL_VALIDATION_RULES
} from "../../../validation/formValidation";
import { getCurrentDate } from "../../../utils/DateUtils";
import { isEmailValidation } from "../../../utils/validation";
import { getDataWithDuplicates } from "../../../utils/getCountriesArray";
import config from '../../../config/server'

const Save = (state) => {
  Api.BackgroundPost("/api/application/company", state).then(() => {});
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CompanyDetails = () => {
  let formActualValues = {};
  let { slug } = useParams();
  const [error, setError] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [data] = Api.Get("/api/application/company/" + slug);
  const [form, setForm] = useState({
    form_url: slug,
    type_id: data?.item?.company?.id,
    legal_address: { laPostcode: "",  legalAddress: "" },
    laRegCountry: "",
    name: "",
    registration_no: "",
    registration_date: "",
    tax_country: "",
    office_address: { oaPostcode: "", officeAddress: "" },
    officeAddress: "",
    oaPostcode: "",
    phone_country: "",
    phone: "",
    email: "",
    business_description: "",
    licIssAuthority: "",
    licIssCountry: "",
    licIssDate: "",
    licNumber: "",
  });
  const [search, setSearch] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [companies, setCompanies] = useState(null);
  const { companyDetails } = useStore();
  const { readySubmit, setReadySubmit } = useStore();
  const required = (value) => (value ? undefined : "Required");
  const mustBeNumber = (value) => !value ? "Required" : isNaN(value) ? "Must be a number" : undefined;

  const formValuesDidChanged = (formValues) => {
    formActualValues = formValues;
  };

  const arrOfficeAndLegalAddressesSame = (actualData) => {
    return (
      actualData?.officeAddress === actualData?.legalAddress &&
      actualData?.legalAddress !== "" &&
      actualData?.legalAddress !== null &&
      actualData?.legalAddress !== undefined &&
      actualData?.oaPostcode === actualData?.laPostcode &&
      actualData?.laPostcode !== "" &&
      actualData?.laPostcode !== undefined &&
      actualData?.laPostcode !== null
    )
  };

  const executeCompanySearchIfNeeded = (userChanges) => {
    setCompanies(null);

    if (Object.keys(userChanges).length === 1 && userChanges.hasOwnProperty("name")) {
      setSearch(userChanges.name);
    }
  };

  const AutoSaver = async (values, valuesChanged) => {
    if (
      Object.keys(valuesChanged).length === 1 &&
      valuesChanged.hasOwnProperty('email')
    ) {
      return await isEmailValidation(valuesChanged, setError, () => Save({ ...form, ...values }))
    }

    if (
      Object.keys(valuesChanged).length < 5 &&
      valuesChanged.hasOwnProperty('tax_country')
    ) {
      const autoFillValue = getDataWithDuplicates.find(element => element.iso3 === valuesChanged?.['tax_country']);
      const actualValues = { ...values, phone_country: autoFillValue?.value }

      setForm(actualValues);
      return Save({ ...form, ...actualValues });
    }

    if (
      (Object.keys(valuesChanged).length <= 2 && isChecked &&
      valuesChanged.hasOwnProperty('legalAddress')) ||
      (Object.keys(valuesChanged).length <= 2 && isChecked &&
      valuesChanged.hasOwnProperty('laPostcode'))
    ) {
      const actualValues = { ...values, officeAddress: values?.legalAddress, oaPostcode: values?.laPostcode };
      setForm(actualValues);
      return Save({ ...form, ...actualValues });
    }

    if (
      Object.keys(valuesChanged).length === 1 &&
      (valuesChanged.hasOwnProperty('officeAddress') || valuesChanged.hasOwnProperty('oaPostcode'))
    ) {
      setIsChecked(arrOfficeAndLegalAddressesSame(values));
    }

    Save({ ...form, ...values });
    executeCompanySearchIfNeeded(valuesChanged);

    progressBar(values)
    await sleep(1000);
  };

  const onSubmit = async () => {
    await setReadySubmit({ ...readySubmit, company: true });
  };

  const progressBar = (values) => {
    let requiredFields = form.need_license === "yes" ? {
      ...COMPANY_DETAILS_INITIAL_VALIDATION_RULES,
      ...COMPANY_DETAILS_ADDITIONAL_VALIDATION_RULES
    } : COMPANY_DETAILS_INITIAL_VALIDATION_RULES;
    const [, progress] = FormValidator(values, requiredFields);
    companyDetails.setDetailsPercent(progress);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    const laObj = {
      oaPostcode: !isChecked ? formActualValues?.laPostcode : null,
      officeAddress: !isChecked ? formActualValues?.legalAddress : null,
      office_address: {
        oaPostcode: !isChecked ? formActualValues?.laPostcode : null,
        officeAddress: !isChecked ? formActualValues?.legalAddress : null
      }
    };
    setForm({ ...formActualValues, ...laObj });
  };

  useEffect(() => {
    if (data && !data.success && data.code) window.location = '/';

    if (data && data.item && data.item.company) {
      data.item.company["form_url"] = slug;
      let company = { ...data.item.company };
      setCompanyId(company.id)
      company = { ...company, ...company?.legal_address };
      company = { ...company, ...company?.office_address };
      if (Object.values(company).length > 1) {
        setIsChecked(arrOfficeAndLegalAddressesSame(company));
      }
      setForm((prev) => ({ ...prev, ...company }));
    }
  }, [data]);

  useEffect(() => {
    if (formActualValues?.laRegCountry === "GBR" && search?.length > 1) {
      Api.instance
        .get(config.baseUrl + "/api/company_info?q=" + search)
        .then((res) => {
          setCompanies(res.data.item);
        })
        .catch((e) => console.log(e));
    }
  }, [search]);

  document.addEventListener("click", () => {
    setSearch(null);
    setCompanies(null);
  });

  return (
    <>
      <div className={styles.main} id="companyDetails">
        <h3 id={"company_details"}>1.1 Company Details</h3>
        <div>
          <Form
            onSubmit={onSubmit}
            initialValues={form}
            subscription={{}}
            className="hide"
          >
            {({ handleSubmit}) => (
                <form className={"submittableForm"} onSubmit={handleSubmit}>
                <button
                  style={{ display: "none" }}
                  className={`doSubmit`}
                  type="submit"
                >
                  Submit
                </button>
                <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged} />
                <Country
                  placeholder="Country of registration *"
                  name={`laRegCountry`}
                  validate={required}
                  className={styles.input}
                />
                <div>
                  <Input
                    name={`name`}
                    placeholder="Company name *"
                    id={`company_name`}
                    validate={required}
                    className={styles.input}
                  />
                  {companies && search && (
                    <div className={styles.companies_div}>
                      {companies.map((company, index) => (
                        <button
                          key={index}
                          className={styles.company_button}
                          onClick={() => {
                            let date = company?.date_of_creation
                              ? moment(company?.date_of_creation).format('x')
                              : null;
                            let tmpForm = Object.assign({}, formActualValues);
                            tmpForm.name = company?.name;
                            tmpForm.registration_no = company?.registration_number;
                            tmpForm.registration_date = date;
                            tmpForm.description = company?.description;
                            tmpForm.laPostcode = company?.legal_address?.postcode;
                            tmpForm.laRegCountry = 'GBR';
                            tmpForm.legalAddress = company?.legal_address?.address;
                            setForm(tmpForm);
                            setCompanies(null);
                          }}
                        >
                          {company.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <Input
                  placeholder="Operating as Trade or Business name"
                  name={`business_name`}
                  className={styles.input}
                />
                <div className={styles.uneven_fields_section}>
                  <Input
                    placeholder="Registration No *"
                    name={`registration_no`}
                    validate={required}
                  />
                  <DatePicker
                    placeholder="Registration date *"
                    maxDate={new Date()}
                    name={`registration_date`}
                    validate={required}
                  />
                </div>
                <Country
                  placeholder="Country of tax residency *"
                  title="Country of tax residency *"
                  name={`tax_country`}
                  validate={required}
                  className={styles.input}
                />
                <div className={styles.justify_center_section}>
                  <Input placeholder="VAT number (if available)" name={`vat`} />
                  <Input placeholder="LEI-code (if available)" name={`el`} />
                </div>
                <span className={styles.section_title}>Legal address</span>
                <div className={styles.display_flex_section}>
                  <Input
                    placeholder="Legal address *"
                    name={`legalAddress`}
                    validate={required}
                  />
                  <Input
                    placeholder="Postcode *"
                    name={`laPostcode`}
                    validate={required}
                  />
                </div>
                <span className={styles.section_title}>Office address</span>
                <FpCheck
                  checked={isChecked}
                  onChange={handleChecked}
                  title="Office address is same as Legal address"
                />
                <div className={styles.display_flex_section}>
                  <Input
                    placeholder="Office address *"
                    name={`officeAddress`}
                    validate={required}
                  />
                  <Input
                    placeholder="Postcode *"
                    name={`oaPostcode`}
                    validate={required}
                  />
                </div>
                <span className={styles.section_title}>Company contacts</span>
                <div className={styles.display_flex_reverse_section}>
                  <PhoneCode
                    placeholder="Phone code *"
                    name={`phone_country`}
                    validate={required}
                  />
                  <Input
                    placeholder="Phone number *"
                    name={`phone`}
                    type="number"
                    validate={mustBeNumber}
                  />
                </div>
                <div className={styles.justify_center_section}>
                  <Input
                    placeholder="Email *"
                    name={`email`}
                    validate={validEmail}
                    error={error}
                  />
                  <Input
                    placeholder="Website *"
                    name={`website`}
                    validate={validWebsite}
                  />
                </div>
                <div className="radio_component">
                  <h4>Does the Company´s activity require a license *</h4>
                  <Radio
                    className="container"
                    name={`need_license`}
                    validate={required}
                    onClick={(e) => {
                      let formValues = { ...formActualValues, need_license: e.target.value }
                      setForm(formValues);
                    }}
                  />
                </div>
                {form?.need_license === "yes" && (
                  <React.Fragment>
                    <div className={styles.justify_center_section}>
                      <Country
                        placeholder="License issuing country *"
                        name={`licIssCountry`}
                        validate={required}
                      />
                      <Input
                        placeholder="Licensing authority *"
                        name={`licIssAuthority`}
                        validate={required}
                      />
                    </div>
                    <div className={styles.uneven_fields_section}>
                      <Input
                        placeholder="License number *"
                        name={`licNumber`}
                        validate={required}
                      />
                      <DatePicker
                        placeholder="License issuing date *"
                        maxDate={getCurrentDate()}
                        name={`licIssDate`}
                        validate={required}
                      />
                    </div>
                  </React.Fragment>
                )}
                <label>
                  <span className={styles.section_title}>
                    Detailed Business Description *
                  </span>
                  <TextArea
                    placeholder="Business description *"
                    name={`business_description`}
                    validate={required}
                  />
                </label>
              </form>
            )}
          </Form>
        </div>
        <span className={styles.some_text_styles}>
          Please provide detailed information about the applicant’s business
          practices. Please describe in detail how applicant’s business is
          built: organizational structure, source of revenues, main expenses,
          technology, number of employees, marketing strategy, clients
          acquisition channel etc.
        </span>
        <FpFile
          uploads={form?.uploads}
          section={`company`}
          slug={slug}
          title={"Drop your documents here!"}
          componentId={companyId}
          type="company"
          upload_type={[
            "Certificate of Incorporation",
            "Memorandum and Articles of Association",
            "Business Bank Statements for the last 3 months / Processing history for the last 6 months",
            "Last year financial statements / Business plan if you're a startup"
          ]}
        />
        <span className={styles.bottom_line}></span>
      </div>
    </>
  );
};

export default CompanyDetails;
