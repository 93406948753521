import React from "react"
import { useHistory } from "react-router-dom";
import { SuccessImage } from "../../../pages/images"
import styles from "./SuccessSubmit.module.scss"

function SuccessSubmit() {
    return (
        <div className={styles.main}>
            <h1 className={styles.title}>
                Your questionnaire has been sent successfully
            </h1>
            <p className={styles.msg}>
                Thank you for your enquiry. It has been forwarded to the relevant department and will be dealt with as soon as possible. We aim to respond within 24 hours.
            </p>
            <div className={styles.wrapper}>
                <a href={"https://fmpay.co.uk"} className={styles.btn}>
                    Back to Home
                </a>
            </div>
            <div className={styles.imgdiv}>
                <SuccessImage />
            </div>    
        </div>
    )
}

export default SuccessSubmit