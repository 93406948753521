import React, { useState, useRef } from "react";
import styles from './FpTextArea.module.scss'
import errorStyles from "../style.module.scss";

const FpTextArea = ({
    name,
    placeholder,
    defaultValue = '',
    error,
    height = 'unset',
    onChange = () => {},
    className,
}) => {
  const [state, setState] = useState(true)
  const input = useRef()
  const focusEvent = () => {
    setState(false)
    input.current?.focus()
  }
    return (
      <>
        <label className={`${styles.textarea_label} ${className} ${error && errorStyles.validator_error_style}`}>
            <label
              className={(state && !defaultValue?.length ) ? styles.textarea_placeholder : styles.textarea_placeholder_not_empty}
              onClick={() => focusEvent()}
            >
              {placeholder}
            </label>
            <textarea
              rows={1}
              defaultValue={defaultValue}
              value={defaultValue}
              name={name}
              style={{minHeight: height}}
              onFocus={() => setState(false)}
              onKeyUp={(e) => {
                if (e.target.value === "") {
                  setState(true)
                } else if (state) {
                  setState(false)
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setState(true)
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChange(null)
                } else {
                  onChange(e.target.value.replace(/  +/g, ' ').trimStart());
                }
              }}
            />
        </label>
        {error && <span className={errorStyles.validator_text}>{error ?? ''}</span>}
      </>
    )
}

export default FpTextArea
