import React from "react";
import Shareholder from "./Shareholder";
import styles from "./Shareholders.module.scss";

export default function Shareholders() {

    return (
        <>
            <div className={styles.shareholders} id="shareholders_a">
                <h2 id="shareholders">2. Shareholders</h2>
                <span>
                    Please provide information about the applicant’s shareholder(s) / partners and their
                    beneficial owner(s). Please add all shareholders who hold {">"} 10% of shares or voting
                    rights.
                </span>
                <Shareholder />
            </div>
        </>
    );
}
