import React from "react"
import { SuccessImage } from "../../../pages/images"
import styles from "./SuccessLiveness.module.scss"

function SuccessLiveness() {
    return (
        <div className={styles.main}>
            <h1 className={styles.title}>
                Thank you for completing our ID verification procedure.
            </h1>
            <p className={styles.msg}>
                Our customer onboarding team will contact you with the next steps.
            </p>
            <div className={styles.imgdiv}>
                <SuccessImage />
            </div>
        </div>
    )
}

export default SuccessLiveness
