import React, { useState, createContext, useContext } from 'react';

export const ManagerContext = createContext();

export const ManagerStore = (props) => {
    const [managerState, setManagerState] = useState([]);

    return (
        <ManagerContext.Provider
            value={{
              managerState,
              setManagerState,
            }}>
            {props.children}
        </ManagerContext.Provider>
    );
};

const useManagerStore = () => useContext(ManagerContext);

export default useManagerStore;
