import React, { useState, createContext, useContext } from "react";

export const StoreContext = createContext();

export const Store = (props) => {
  const [detailsPercent, setDetailsPercent] = useState(0);
  const [businessPercent, setBusinessPercent] = useState(0);
  const [managerExpanded, setManagerExpanded] = useState({})
  const [acquiringPercent, setAcquiringPercent] = useState(0);
  const [settlementPercent, setSettlementPercent] = useState(0);
  const [shareholderValidator, setShareholderValidator] = useState([]);
  const [shareholderExpanded, setShareholderExpanded] = useState({});
  const [loader, setLoader] = useState(null);
  const [readySubmit, setReadySubmit] = useState({
    company: false,
    business: false,
    acquiring: false,
    settlement: false,
    shareholder: false,
    director: false,
  });
  const [readyInitialSubmit, setReadyInitialSubmit] = useState({
    company: false,
    business: false,
    acquiring: false,
    settlement: false,
    shareholder: false,
    director: false,
  });

  return (
    <StoreContext.Provider
      value={{
        loader,
        setLoader,
        companyDetails: {
          detailsPercent,
          setDetailsPercent,
        },
        businessInfo: {
          businessPercent,
          setBusinessPercent,
        },
        managerExpandeds: {
          managerExpanded,
          setManagerExpanded
        },
        acquiringInfo: {
          acquiringPercent,
          setAcquiringPercent,
        },
        settlementInfo: {
          settlementPercent,
          setSettlementPercent,
        },
        shareholderValidation: {
          shareholderValidator,
          setShareholderValidator,
        },
        readySubmit,
        setReadySubmit,
        initialSubmit: {
          readyInitialSubmit,
          setReadyInitialSubmit,
        },
        shareholderExpandeds: {
          shareholderExpanded,
          setShareholderExpanded
        }
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export default useStore;
