import React, {useEffect, useState} from "react";
import styles from "./Declaration.module.scss"
import Loader from "./Loader/Loader";
import FpCheck from "./UI/FpCheck/FpCheck";
import useStore from "../contexts/context";
import ManagerStore from "../contexts/managerContext";
import ShareholderStore from "../contexts/shareholderContext";
import * as Api from "../utils/api";
import {useParams} from "react-router-dom";
import useAlertContext from "../contexts/alertContext";

const Declaration = () => {
    const {companyDetails, businessInfo, acquiringInfo, settlementInfo} = useStore();
    const { managerState } = ManagerStore();
    const { shareholderState } = ShareholderStore();
    const { setAlert } = useAlertContext();
    const [loading, setLoading] = useState(false)
    const [confirmTerms, setConfirmTerms] = useState(false)
    const [certifiedInformation, setCertifiedInformation] = useState(false)
    const [error, setError] = useState(false)
    let {slug} = useParams();
    const {managerExpandeds: { setManagerExpanded }, shareholderExpandeds: { setShareholderExpanded }, readySubmit} = useStore();
    const DeclarationApiPath = "/api/application/declaration";
    const [data] = Api.Get( DeclarationApiPath + "?form_url=" + slug);

    const canSubmitForm = () => {
        let canFormBeSubmitted = true;

        if (companyDetails.detailsPercent !== 100 && !readySubmit.company) canFormBeSubmitted = false;
        if (businessInfo.businessPercent !== 100 && !readySubmit.business) canFormBeSubmitted = false;
        if (acquiringInfo.acquiringPercent !== 100 && !readySubmit.acquiring) canFormBeSubmitted = false;
        if (settlementInfo.settlementPercent !== 100 && !readySubmit.settlement) canFormBeSubmitted = false;

        {
            shareholderState?.map((item, index) => {
                item.beneficial_owner.map(i => {
                    if (!item.isValid || !i.isValid) {
                        setShareholderExpanded(prev => ({
                            ...prev,
                            [`shareholder_${index}`]: true
                        }))
                        canFormBeSubmitted = false;
                    }
                })
            })
        }
        {
            managerState?.map((item, index) => {
                if (!item.isValid) {
                    setManagerExpanded(prev => ({
                        ...prev,
                        [`manager_${index}`]: true
                    }))
                    canFormBeSubmitted = false;
                }
            })
        }

        return canFormBeSubmitted
    }

    function submitForms() {
        let submittableReactForms = document.getElementsByClassName('submittableForm');
        for (let i = 0; i < submittableReactForms.length; i++) {
            submittableReactForms[i].dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }))
        }
        setLoading(false)
    }

    const Submit = () => {
        setLoading(true)
        setError(!canSubmitForm())
        if (canSubmitForm()) {
            Api.BackgroundPost(DeclarationApiPath, {"form_url": slug}).then(() => {
                window.location.href = '/success';
            }).catch(() => {
                setAlert({
                    open: true,
                    variant: 'error',
                });
            }).finally(() => {
                setLoading(false)
            })
        } else {
            submitForms()
        }
    }

    function handleConfirmationChanges(checked) {
        setConfirmTerms(checked)
        executeAPICallForOptionsUpdates({
            "form_url": slug,
            "agree_with_terms": checked,
            "i_certify": certifiedInformation
        })
    }

    function handleCertificationChanges(checked) {
        setCertifiedInformation(checked)
         executeAPICallForOptionsUpdates({
             "form_url": slug,
             "agree_with_terms": confirmTerms,
              "i_certify": checked
         })
    }

    function executeAPICallForOptionsUpdates(params) {
        Api.BackgroundPut(DeclarationApiPath, params).finally(() => {})
    }

    function setDeclarationOptions(declarationObject) {
        setCertifiedInformation(declarationObject?.i_certify)
        setConfirmTerms(declarationObject?.agree_with_terms)
    }

    useEffect( () => {
        if (data?.item?.declaration) {
            const declarationObject = data?.item?.declaration
            setDeclarationOptions(declarationObject)
        }
    }, [data]);

    return (
        <div className={styles.declaration}>
            <h2 id={'declaration'}>4. Declaration</h2>
            <div className={styles.checkbox_component}>
                <div>
                    <FpCheck checked={confirmTerms} title={''} onChange={(_, __, checked) => {
                        handleConfirmationChanges(checked)
                    }}/>
                    <label className={styles.label}>
                        I confirm that I have read and agreed with
                        <a target="_blank" href="https://fmpay.co.uk/terms/">Terms of Use,</a>
                        <a target="_blank" href="https://fmpay.co.uk/policy-privacy/">Privacy Policy</a>
                        and
                        <a target="_blank" href="https://fmpay.co.uk/merchant-terms/"> Financial Services Terms</a>
                    </label>
                </div>
                <div>
                    <FpCheck checked={certifiedInformation} title={''} onChange={(_, __, checked) => {
                        handleCertificationChanges(checked)
                    }}/>
                    <label className={styles.label}>
                        I certify that the information in this document is complete and true.
                        I undertake to immediately inform FM Finance Ltd in writing on any changes
                        in the above information. I am informed, that Customer
                        Questionnaire and its annexes are integral part of the Agreement.
                    </label>
                </div>
                {error &&
                <p className={styles.errormsg}>There are some data entered incorrectly or not all required information is provided. Please review the form and try again.</p>}
                <button
                    onClick={(e) => Submit(e)}
                    disabled={loading || !(confirmTerms && certifiedInformation)}
                >
                    {loading && <span className={styles.loading_span}><Loader/></span>}
                    <span>Submit</span>
                </button>
            </div>
        </div>
    )
}

export default Declaration;
