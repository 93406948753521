import React, { useState, createContext, useContext } from 'react';

export const AlertContext = createContext();

export const Alert = (props) => {
  const initialState = {
    open: false,
    variant: '',
    message: '',
  }
  const [alert, setAlert] = useState(initialState);

  return (
    <AlertContext.Provider
      value={{
        alert,
        initialState,
        setAlert
      }}>
      {props.children}
    </AlertContext.Provider>
  );
};

const useAlertContext = () => useContext(AlertContext);

export default useAlertContext;
