import React from "react";
import { Field } from "react-final-form";
import FpRadio from "./FpRadio";
import styles from "./style.module.scss";

class Radio extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Field {...this.props}>
          {({ input, meta }) => (
            <div>
              {meta.touched && meta.error && (
                <div className={styles.validator_text_radio}>{meta.error}</div>
              )}
              <FpRadio
                reactForm={true}
                options={[{ no: "No" }, { yes: "Yes" }]}
                title={["No", "Yes"]}
                className={`container`}
                name={input.name}
                defaultValue={input.value}
                onChange={input.onChange}
                onClick={this.props.onClick}
                error={meta.touched && meta.error}
              />
            </div>
          )}
        </Field>
      </div>
    );
  }
}

export default Radio;
