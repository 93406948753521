import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import FpFile from "../../UI/FpFile/FpFile";
import DatePicker from "../../UI/Date";
import Input from "../../UI/Input";
import FpSelect from "../../UI/FpNumberSelect";
import * as Api from "../../../utils/api";
import { useParams } from "react-router-dom";
import FormValidator from "../../../Helpers/FormValidator";
import useStore from "../../../contexts/context";
import { Form, Field } from "react-final-form";
import AutoSave from "../../../Helpers/AutoSave";
import {BUSINESS_INFO_VALIDATION_RULES} from "../../../validation/formValidation";
import { getCurrentDate } from "../../../utils/DateUtils";

const Save = (state) => {
  if(Object.keys(state).length > 3) {
    Api.BackgroundPost("/api/application/business", state).then(() => {
    });
  }
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const FpNumberArray = [1, 2, 3, 4];
const FpSaqArray = [
  'SAQ-A',
  'SAQ-A-EP',
  'SAQ-B',
  'SAQ-B-IP',
  'SAQ-C',
  'SAQ-C-VT',
  'SAQ-D'
];

const BusinessInfo = () => {
  let formActualValues = {};
  let { slug } = useParams();
  const { readySubmit, setReadySubmit } = useStore();
  const [data] = Api.Get("/api/application/business/" + slug);
  const [isFileUploadError, setIsFileUploadError] = useState(false)
  const [changeError, setChangeError] = useState(false)
  const [form, setForm] = useState({
    form_url: slug,
    type_id: data?.item?.business?.id,
    domains: "",
    domain_owner: "",
    isFileUploaded: false
  });
  const { businessInfo } = useStore();
  const required = (value) => (value ? undefined : "Required");

  const handleIsFileUploads = () => {
    if (!form?.isFileUploaded) {
      setIsFileUploadError(true)
    } else {
      setIsFileUploadError(false)
    }
  }

  const AutoSaver = async (values) => {
    Save(values);
    progressBar(values)
    await sleep(1000);
  };

  const progressBar = (values) => {
    const [warnings, progress] = FormValidator(values, BUSINESS_INFO_VALIDATION_RULES);
    businessInfo.setBusinessPercent(progress);
  };

  function formValuesDidChanged (formValues) {
    formActualValues = formValues;
  }

  useEffect(() => {
    if (data && data.item && data.item.business) {
      data.item.business["form_url"] = slug;
    }
    if (data?.item?.business?.uploads?.length) {
      setForm(() => ({
        ...data?.item?.business,
        isFileUploaded: true
      }))
    } else {
      setForm(() => ({
        ...data?.item?.business,
        isFileUploaded: false
      }))
    }
  }, [data]);

  useEffect(() => {
    setForm(() => ({
      ...formActualValues,
      form_url: slug,
      type_id: data?.item?.business?.id,
      isFileUploaded: !isFileUploadError
    }))
  }, [isFileUploadError, changeError])

  const onSubmit = async () => {
    handleIsFileUploads()
    await setReadySubmit({ ...readySubmit, business: true });
  };

  return (
    <div className="bussines_info" id="businessInfo">
      <h3 id={"business_info"}> 1.2 Business Information</h3>
      <Form
        onSubmit={onSubmit}
        initialValues={form}
        subscription={{}}
        className="hide"
      >
        {({ handleSubmit}) => (
            <form className={"submittableForm"} onSubmit={handleSubmit}>
            <button
              style={{ display: "none" }}
              className={`doSubmit`}
              type="submit"
            >
              Submit
            </button>
            <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged} />
            <Input
              placeholder="Company website(s) for acquiring *"
              name={`domains`}
              validate={required}
              className={styles.input}
            />
            <Input
              placeholder="Owner of domain name *"
              name={`domain_owner`}
              validate={required}
            />
            <span className={styles.title}>
              Test account (Customer-facing control panel, shopping cart, etc.
              If available.)
            </span>
            <div className={styles.justify_center_section}>
              <Input placeholder="Login" name={`login`} />
              <Input placeholder="Password" name={`password`} />
            </div>
            <span className={styles.section_title}>
              Currently used MCC codes
            </span>
            <Input placeholder="MCC codes" name={`mmc`} />
            <span className={styles.title}>
              PCI DSS certificate *
            </span>
            <Field name="level" validate={required} >
              {(props) => (
                <FpSelect
                  title="Level *"
                  defaultValue={props.input.value}
                  onChange={props.input.onChange}
                  name={props.input.name}
                  meta={props.meta}
                  array={FpNumberArray}
                  placeholder="Pick a level"
                />
              )}
            </Field>
              <Field name="saq_type" validate={required} >
                {(props) => (
                  <FpSelect
                    title="SAQ Type *"
                    defaultValue={props.input.value}
                    onChange={props.input.onChange}
                    name={props.input.name}
                    meta={props.meta}
                    array={FpSaqArray}
                    placeholder="Pick a SAQ"
                  />
                )}
              </Field>
              <div className={styles.justify_center_section}>
                <DatePicker
                  placeholder="SAQ/AOC date *"
                  name={`pci_saq`}
                  maxDate={getCurrentDate()}
                  validate={required}
                />
                <DatePicker
                  placeholder="ASV scan date *"
                  name={`pci_asv`}
                  maxDate={getCurrentDate()}
                  validate={required}
                />
              </div>
            <FpFile
              uploads={form?.uploads}
              componentId={form?.id}
              title={"Drop your documents here!"}
              type="business"
              upload_type={["AOC/SAQ + ASV scan results","Proof of domain ownership"]}
              isFileUploadError={isFileUploadError}
              setIsFileUploadError={setIsFileUploadError}
              setForm={setForm}
              setChangeError={setChangeError}
              asterix="*"
            />
            <span className={styles.bottom_line_open}/>
          </form>
        )}
      </Form>
    </div>
  );
};

export default BusinessInfo;
