import React, { useState, useEffect } from "react";
import Select from "react-select";
import styles from "./FpNumberSelect.module.scss";

const FpSelect = ({
  title,
  id = "",
  defaultValue,
  onChange = () => {},
  className,
  meta,
  array,
  placeholder
}) => {
  const [value, setValue] = useState(defaultValue);
  const [bordered, setBordered] = useState(false);

  const data = array.map((el) => {
    return {
      value: el,
      label: el,
    };
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: "#333333",
        "&:hover": {
          background: "#ced5dc !important",
          div: {
            background: "#ced5dc !important",
          },
        },
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: "7px 0px",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        marginLeft: "3px",
        marginTop: "8px",
        top: "25%"
      };
    },
    dropdownIndicator: (styles, state ) => {
      state.selectProps.menuIsOpen ? setBordered(true) : setBordered(false);
      return {
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        marginTop: "-30px",
        color: "#151724"
      }
    },
    control: (styles) => {
      return {
        ...styles,
        background: '#f6f7f8'
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: '-15px',
        width: 'calc(100% + 30px)',
        boxShadow: "0px 8px 10px #dedada, -10px 8px 15px #dedada, 10px 8px 15px #dedada !important",
        background: '#fff'
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 333,
      };
    },
  };

  return (
    <div className={styles.select_wrapper}>
      <label
        className={
          `${styles.select_label}
          ${className}
          ${bordered && styles.bordered}
          ${meta.error && meta.touched && styles.validator_error_style}`
        }
        id={id}
      >
        <span className="select_title">{title}</span>
        <Select
          autoComplete="off"
          type="number"
          onChange={(e) => {
            setValue(e.value);
            onChange(e.value);
          }}
          placeholder={placeholder}
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          options={data}
          styles={colourStyles}
          value={data.find((el) => el.value === value)}
          className={styles.country_select}
          option={value}
          isSearchable={false}
        />
      </label>
      {meta.error && meta.touched && (
        <span className={styles.validator_text}>{meta.error}</span>
      )}
      </div>
  );
};

export default FpSelect;
