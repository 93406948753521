import React, { useState, useEffect } from "react";
import styles from "./FpSelect.module.scss";
import countries from "../../../../countries";
import Select from "react-select";
import errorStyles from "../style.module.scss";

const FpCountry = ({
  id = "",
  defaultValue = "",
  onChange = () => {},
  className,
  placeholder,
  error,
}) => {
  const [value, setValue] = useState("");

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#333333",
        backgroundColor: isFocused ? "#F6F7F8" : isSelected ? "#ced5dc" : null,
        "&:hover": {
          background: "#F6F7F8 !important",
          div: {
            background: "#F6F7F8 !important",
          },
        },
        "&:active": {
          background: "#F6F7F8 !important",
        }
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
        zIndex: 99,
      }
    },
    control: (styles) => {
      return {
        ...styles,
        boxShadow: "unset",
      }
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: "0",
        maxHeight: "22px",
        alignItems: "flex-end"
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        marginLeft: "3px",
        color: "#151724",
      };
    },
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: "#151724",
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 333,
      };
    },
  };


  const data = countries.map((el) => {
    return {
      value: el.iso3,
      name: el.name,
      label: (
        <div>
          <img src={`/countries/${el.iso2.toLowerCase()}.svg`} />
          {el.name}
        </div>
      ),
    };
  });
  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    )
      return true;
    else return false;
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <div className={`${styles.select_label} ${className}`} id={id}>
        <p className={styles.date_placeholder_not_empty}>
          {!value ? null : placeholder}
        </p>
        <Select
          className={`${styles.sectionTest} ${styles.country_select}`}
          onChange={(e) => {
            setValue(e.value);
            setValue(e.value)
            onChange(e.value);
          }}
          onKeyDown={(e) => {
            setValue(e.target.value);
          }}
          filterOption={customFilter}
          placeholder={placeholder || "Choose Country"}
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          options={data}
          styles={colourStyles}
          value={data.find((el) => el.value === value)}
          option={value}
        />
      </div>
      {error && <span className={errorStyles.validator_text}>{error}</span>}
    </div>
  );
};

export default FpCountry;
