import React, { useEffect, useState } from 'react';
import ArticleSlide from "../components/ArticleSide";
import StartDoc from "../components/StartDoc";
import Questionnaire from "../components/Questionnaire";
import Shareholders from "../components/Shareholders/Shareholders";
import Management from "../components/Management";
import Declaration from "../components/Declaration";
import { Dialog } from '../components/dialog/dialog'
import useDialogStore from "../contexts/dialogStore";
import { useParams, useHistory } from "react-router-dom";
import * as Api from "../utils/api";
import { useMatomo } from '@datapunt/matomo-tracker-react'
const Home = () => {
    const dialogContext = useDialogStore()
    const { slug } = useParams()
    const [isValidUrl, setValidUrl] = useState(false)
    const history = useHistory()

    const { trackPageView } = useMatomo()

    useEffect(() => {
        trackPageView()
        Api.instance.get(`api/application/get-form/${slug}`)
            .then(() => {
                setValidUrl(true)
            })
            .catch(() => {
                setValidUrl(false)
                history.push("/")
            })
    }, [])
    return (
        <>
            {isValidUrl && (
                <div>
                    {dialogContext?.dialogState?.open && <Dialog {...dialogContext} />}
                    <section className="onboarding_form">
                        <ArticleSlide/>
                        <div className="content">
                            <div>
                                <StartDoc />
                                <Questionnaire/>
                                <Shareholders/>
                                <Management/>
                                <Declaration/>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    )
}

    export default Home;
