import countries from "../countries";

export const getUniqArray = (a) => {
  let set = new Set();
  return a.filter(item => {
    if (item.value) {
      return set.has(item.value) ? false : set.add(item.value);
    }
  });
};


export const getDataWithDuplicates = countries.map((el, index) => {
  return {
    value: el.phone,
    name: el.name,
    iso3: el.iso3,
    index,
    label: `${el.phone !== undefined ? `+${el.phone}` : ""}`,
  };
});
