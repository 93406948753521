import React from "react";
import { Field } from "react-final-form";
import FpPhone from "./FpPhone/FpPhone";

class PhoneCode extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Field {...this.props}>
        {({ input, meta }) => (
          <div>
            <FpPhone
              placeholder={this.props.placeholder}
              title={this.props.placeholder}
              defaultValue={input.value}
              onChange={input.onChange}
              name={input.name}
              reactForm={true}
              error={meta.touched && meta.error}
              valueSource={this.props.valueSource}
              setForm={this.props.setForm}
              form={this.props.form}
            />
          </div>
        )}
      </Field>
    );
  }
}

export default PhoneCode;
