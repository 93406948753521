import React, { useState } from "react";
import useManagerStore from "../../contexts/managerContext";
import useShareholderStore from "../../contexts/shareholderContext";
import styles from "./style.module.scss";
import checked from "../../pages/images/checked.png";
import useStore from "../../contexts/context";
import { Link } from 'react-scroll'



const ArticleSlide = () => {
  const { managerState } = useManagerStore();
  const { shareholderState } = useShareholderStore();
  const {
    companyDetails,
    businessInfo,
    acquiringInfo,
    settlementInfo
  } = useStore();
  const shareholder = shareholderState
  const [active, setActive] = useState(false);

  return (
    <article className={styles.article_side}>
      <div className={styles.steps} onClick={() => setActive(true)}>
        Steps
        <img src="/images/arrow-right.svg" alt="" />
      </div>
      <div className={`${styles.content} ${active && styles.open}`}>
        <div className={styles.closeButtonContainer}>
          <span className={styles.closeButton} onClick={() => setActive(false)}>
            Close
          </span>
        </div>
        <div className={styles.start}>
          <h2> Before you start</h2>
        </div>
        <div className={styles.questionnaire}>
          <h4>
            {" "}
            <Link to="questionnaire_a" spy={true} offset={-53}>
              1. Questionnaire
            </Link>
          </h4>
          <div>
            <Link className={styles.title} to="companyDetails" spy={true} activeClass={styles.active} offset={-46}>
              {companyDetails.detailsPercent === 100 ? (
                <span className={styles.success}>
                  <img src={checked} alt="" />
                </span>
              ) : (
                <div className={styles.round}>1</div>
              )}
              <span className={styles.activeSpan}>Company Details</span>
            </Link>
          </div>
          <div>
            <Link className={styles.title} to="businessInfo" spy={true} activeClass={styles.active} offset={-46}>
              {businessInfo.businessPercent === 100 ? (
                <span className={styles.success}>
                  <img src={checked} alt="" />
                </span>
              ) : (
                <div className={styles.round}>2</div>
              )}
              <span className={styles.activeSpan}>Business Information</span>
            </Link>
          </div>
          <div>
            <Link className={styles.title} to="acquiringInfo" spy={true} activeClass={styles.active} offset={-46}>
              {acquiringInfo.acquiringPercent === 100 ? (
                <span className={styles.success}>
                  <img src={checked} alt="" />
                </span>
              ) : (
                <div className={styles.round}>3</div>
              )}
              <span className={styles.activeSpan}>Acquiring Information</span>
            </Link>
          </div>
          <div>
            <Link className={styles.title} to="settlementInfo" spy={true} activeClass={styles.active} offset={-46}>
              {settlementInfo.settlementPercent === 100 ? (
                <span className={styles.success}>
                  <img src={checked} alt="" />
                </span>
              ) : (
                <div className={styles.round}>4</div>
              )}
              <span className={styles.activeSpan}>Settlement Information</span>
            </Link>
          </div>
        </div>
        <div className={styles.shareholders}>
          <h4>
            <Link to={"shareholders_a"} spy={true} offset={-53}>
              2. Shareholders
            </Link>
          </h4>
          {shareholder && !!shareholder?.length ? (
            shareholder.map((item,index) => (
              <div key={item?.id}>
                <Link className={styles.title} to={"shareholder"+index} spy={true} activeClass={styles.active} offset={-53}>
                  {item?.isValid ? (
                    <span className={styles.success}>
                      <img src={checked} alt="" />
                    </span>
                  ) : (
                    <div className={styles.round}>
                      <img src="/images/share-hold.svg" alt="" />
                    </div>
                  )}
                  <span className={styles.activeSpan}>
                    {item?.kind === "individual" &&
                    item?.firstName &&
                    item?.lastName
                      ? item?.firstName +
                        " " +
                        item?.lastName
                      : item?.kind === "corporate" && item?.name ? item?.name
                      : "Shareholder"
                    }
                  </span>
                </Link>
                {item?.beneficial_owner?.map((el) => (
                      <Link
                        className={styles.uboTitle}
                        to={`ubo_${el.id}`}
                        spy={true}
                        activeClass={styles.active}
                        offset={-53}
                        key={el?.id}
                      >
                        <div className={styles.ubo}>
                          <div className={el?.isValid ? styles.uboSuccess : styles.uboNotFill} />
                          <span className={`${styles.uboSpan} ${styles.activeSpan}`}>
                            {el?.firstName && el?.lastName
                              ? el?.firstName + " " + el?.lastName
                              : "Beneficial Owner"}
                          </span>
                        </div>
                      </Link>
                  ))}
                </div>
            ))
          ) : (
            <div>
              <Link className={styles.title} to={"shareholders"} spy={true} activeClass={styles.active} offset={-53}>
                <div className={styles.round}>
                  <img src="/images/share-hold.svg" alt="" />
                </div>
                <span className={styles.activeSpan}>Shareholder</span>
              </Link>
            </div>
          )}
        </div>
        <div className={styles.management}>
          <h4>
            <Link to="management_a" spy={true}>
              3. Management
            </Link>
          </h4>
          {!!managerState?.length ? (
            managerState.map((item, index) => (
              <div key={item.id}>
                <Link className={styles.title} to={"management"+index} spy={true} activeClass={styles.active} offset={-53}>
                  {item?.isValid ? (
                    <span className={styles.success}>
                      <img src={checked} alt="" />
                    </span>
                  ) : (
                    <div className={styles.round}>
                      <img src="/images/director.svg" alt="" />
                    </div>
                  )}
                  <span className={styles.activeSpan}>
                    {item.firstName && item.lastName ? item.firstName + " " + item.lastName : "Director"}
                  </span>
                </Link>
              </div>
            ))
          ) : (
            <div>
              <Link className={styles.title} to={"management_a"} spy={true} activeClass={styles.active} offset={-53}>
                <div className={styles.round}>
                  <img src="/images/director.svg" alt="" />
                </div>
                <span className={styles.activeSpan}>Director</span>
              </Link>
            </div>
          )}
        </div>
        <div className="declaration_article">
          <h4>
            <Link to={"declaration"} spy={true} offset={-53}>
              4. Declaration
            </Link>
          </h4>
          <p className={styles.note}>
            Fields marked with asterisks
            <br />
            (*) are required
          </p>
        </div>
      </div>
    </article>
  );
};

export default ArticleSlide;
