import React, {useEffect, useState} from "react";
import styles from './SrcOfFundsButtons.module.scss'
import FpCheck from "../UI/FpCheck/FpCheck";

const SrcOfFundsButtons = ({
    values = [],
    changeEvent,
    onClick
}) => {
    const allSources = [
        'Salary / honoraria / royalties',
        'Sale of property / business / rent',
        'Return on investments/ dividends',
        'Sale of shares / capital shares',
        'Borrowed funds',
        'Inheritance',
        'Other (please specify)'
    ]
    const [state, setState] = useState([]);
    useEffect(() => {
        setState(values)
    }, [values])
    const changeCheckbox = (src) => {
        if (state?.includes(src)) {
            let res = state.filter(el => el !== src)
            changeEvent(res)
            setState(res)
        } else {
            changeEvent([...state, src])
            setState([...state, src])

        }
    }
    return (
    <div className={styles.sources_benefic}>
        <span className={styles.section_title}>Sources of the Beneficial owner´s funds</span>
        <div>
            {allSources.map((src, ind) => (
                <FpCheck key={ind} checked={state?.includes(src)} title={src} onChange={() => changeCheckbox(src)} onClick={src === "Other (please specify)" ? onClick : () => {}} />
            ))}
        </div>
    </div>
    )
}

export default SrcOfFundsButtons
