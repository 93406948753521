const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateWebsite = (website) => {
  const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/
  return re.test(String(website).toLowerCase());
};

export const validEmail = (value) =>
!validateEmail(value) ? "Email is not valid" : !value ? "Required" : undefined;

export const validWebsite = (value) => {
  if (!value) {
    return undefined
  } else {
    return !validateWebsite(value)
    ? 'Website is not valid'
    : !value
    ? undefined
    : undefined
  }
}

const FormValidator = (form, rules) => {
  const warnings = [];
  let fieldsCount = 0;

  if (form)
    Object.keys(rules)?.map((field) => {
      if (rules[field]) {
        fieldsCount++;
        const rule = rules[field].split(":");
          if (rule[1] === "string") {
            if (typeof form[field] !== "string" || form[field].length < 1 || form[field] === null || !form?.hasOwnProperty(field)) {
              let obj = {};
              obj[field] = "Field is required and value should be an string";
              warnings.push(obj);
            }
          }
          if (rule[1] === "email") {
            if (!validateEmail(form[field]) || form[field].length < 1 || form[field] === null || !form?.hasOwnProperty(field)) {
              let obj = {};
              obj[field] =
                "Field is required and value should be an email address";
              warnings.push(obj);
            }
          }
        if (rule[1] === 'website') {
          if (
            !validateWebsite(form[field]) ||
            form[field].length < 1 ||
            form[field] === null ||
            !form?.hasOwnProperty(field)
          ) {
            let obj = {};
            obj[field] =
              'Field is required and value should be an website address';
            warnings.push(obj);
          }
        }
          if (rule[1] === "object") {
            if (form[field] || form[field] === null || !form?.hasOwnProperty(field)) {
                let obj = {};
                obj[field] = "Field is required inside " + field;
                warnings.push(obj);
            } else {
              let obj = {};
              obj[field] = "Field is required and value should be an object";
              warnings.push(obj);
            }

          }
          if (rule[1] === "number" && (typeof form[field] !== "number" || form[field] === null || !form?.hasOwnProperty(field))) {
            let obj = {};
            obj[field] = "Value should be a number";
            warnings.push(obj);
          }
          if (rule[1] === "timestamp" && (typeof form[field] !== "number" && typeof form[field] !== "string" || form[field] === null || !form?.hasOwnProperty(field) || form[field] === 0)) {
            let obj = {};
            obj[field] = "Value should be a number and greather than 0";
            warnings.push(obj);
          }
          if (
            rule[1] === 'boolean' &&
            (!form[field])
          ) {
            let obj = {};
            obj[field] = 'Value should be true';
            warnings.push(obj);
          }
          if (rule[1] === "phone" && (typeof form[field] !== "number" && typeof form[field] !== "string" || form[field] === null || !form?.hasOwnProperty(field))) {
            let obj = {};
            obj[field] = "Value should be a string or a number and greather than 0";
            warnings.push(obj);
          }
          if (rule[1] === "array") {
            if (form[field]?.length === 0 || form[field] === null || !form?.hasOwnProperty(field)) {
              let obj = {};
              obj[field] = "Array is empty";
              warnings.push(obj);
            }
          }
      }
    });

  return [warnings, Math.round(100 - (warnings.length / fieldsCount) * 100)];
};

export default FormValidator;
