import React, { useState, useEffect } from "react";
import styles from "./FpSelect.module.scss";
import { getUniqArray, getDataWithDuplicates } from "../../../utils/getCountriesArray"
import Select from "react-select";

const FpPhone = ({
  placeholder,
  id = "",
  defaultValue = "",
  onChange = () => {},
  className,
  error,
}) => {
  const [value, setValue] = useState(defaultValue);
  const dataWithoutDuplicates = getUniqArray(getDataWithDuplicates);
  const [bordered, setBordered] = useState(false);
  const compare = ( a, b ) => {
    return a.value - b.value;
    
  }

  const getMatchingResult = (searchText) => {
    const searToLowerCase = searchText.toLowerCase();
    const filter = []
    getDataWithDuplicates.forEach((el) => {
      if (
        el.name.toLowerCase().includes(searToLowerCase) ||
        el.value?.toString().includes(searchText.toString()) ||
        el.iso3.toLowerCase().includes(searToLowerCase)
      ) {
        filter.push(el)
      }
    })
    return filter;
  };

  const countryCodesMatch = (searchText, countryCode) => {
    const similarCountries = getMatchingResult(searchText);
    let doMatch = false;
    similarCountries.forEach((el) => {
      if (el.value === countryCode){
        doMatch = true;
      }
    })
    return doMatch;
  }
  
  const customFilter = (option, searchText) => {
    return (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data.label?.toString().includes(searchText.toString()) ||
      option.data.iso3.toLowerCase().includes(searchText.toLowerCase()) ||
      countryCodesMatch(searchText, option.data.value)
    )
  };

  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: "#333333",
        "&:hover": {
          background: "#ced5dc !important",
          div: {
            background: "#ced5dc !important",
          },
        },
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: 0
      };
    },
    placeholder: (styles) => ({ ...styles, color: "#151724" }),
    dropdownIndicator: (styles, state) => {
      state.selectProps.menuIsOpen ? setBordered(true) : setBordered(false);
      return {
        ...styles,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        marginTop: "-30px",
        color: "#151724"
      };
    },
    control: (styles) => {
      return {
        ...styles,
        background: '#f6f7f8'
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: '-15px',
        width: 'calc(100% + 30px)',
        boxShadow: "0px 8px 10px #dedada, -10px 8px 15px #dedada, 10px 8px 15px #dedada !important",
        background: '#fff'
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 200,
        zIndex: 10
      }
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div style={{ position: "relative" }}>
      <label
        className={`${styles.select_label} ${className}  ${
          error && styles.validator_error_style
        } ${bordered && styles.bordered}`}
        id={id}
      >
        {value && <span className="select_title">{placeholder}</span>}
        <Select
          onChange={(e) => {
            setValue(e.value);
            onChange(e.value)
          }}
          filterOption={customFilter}
          isSearchable
          placeholder={placeholder}
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          options={dataWithoutDuplicates.sort(compare)}
          styles={colourStyles}
          value={dataWithoutDuplicates.find((el) => {
            if (el.value == value) {
              return value;
            }
          })}
          className={`${styles.country_select}`}
        />
      </label>
      {error && <span className={styles.validator_text}>{error}</span>}
    </div>
  );
};

export default FpPhone;
