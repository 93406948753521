import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styles from "./FpDatePicker.module.scss";
import errorStyles from "../style.module.scss";
import "react-datepicker/dist/react-datepicker.css";
export default function FpDatePicker({
  placeholder,
  disabled = false,
  dateFormats = ["dd/MM/yyyy", "dd.MM.yyyy"],
  onChange,
  defaultValue = "",
  maxDate ,
  minDate = new Date("01/01/1900"),
  className,
  isUBODatePicker,
  error,
}) {
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
      setValue(+defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <div className={`${styles.date_label} ${styles[className]} ${className}`}>
        <p className={styles.date_placeholder_not_empty}>
          {!value ? null : placeholder}
        </p>
        <div>
          <DatePicker
            placeholderText={placeholder}
            disabled={disabled}
            onChange={(date) => {
              setIsOpen(false)
              if (date && (date >= minDate) && (maxDate ? date <= maxDate: true)) {
                onChange(date.getTime());
              } else onChange(null)
            }}
            dateFormat={dateFormats}
            peekNextMonth={false}
            showMonthDropdown
            showYearDropdown
            maxDate={maxDate}
            minDate={minDate}
            selected={value}
            dropdownMode="select"
            open={isOpen}
            onInputClick={() => setIsOpen(true)}
            onClickOutside={() => setIsOpen(false)}
          />
          <img
            className={
              !isUBODatePicker ? styles.date_icon : styles.UBO_data_icon
            }
            onClick={() => setIsOpen(true)}
            src={"/images/date_icon.svg"}
          />
        </div>
      </div>
      {error && <span className={errorStyles.validator_text}>{error}</span>}
    </div>
  );
}
