import React from "react"
import loader from "../../pages/images/Spinner.svg"
import styles from "./Loader.module.scss"

function Loader() {
    return (
        <div className={styles.spinner}>
            <img src={loader} alt="" />
        </div>
    )
    
}

export default Loader