import React, { useEffect, useState } from 'react'
import styles from './FpFile.module.scss'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import conf from '../../../config/server'
import * as Api from "../../../utils/api";
import { useParams } from "react-router-dom";
import useAlertContext from "../../../contexts/alertContext";

const FpFile = ({
    uploads,
    ubo = false,
    upload_type = [],
    type,
    isFileUploadError = false,
    setIsFileUploadError = () => {},
    setChangeError = () => {},
    asterix = '',
    componentId
}) => {
  const { slug } = useParams();
  const { setAlert } = useAlertContext();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isTooBig, setIsTooBig] = useState(false)
  const types = {
    'company': 0,
    'shareholder': 1,
    'ubo': 2,
    'manager': 3,
    'business': 4
  }
  const [loading, setLoading] = useState(false)

  const getUploadParams = () => {
    return {
        url: `${conf.baseUrl}/api/uploads?form_url=${slug}`
    }
  }

  const maxSize = 26214400

  const RemoveHandler = (data) => {
      Api.instance.delete(`/api/uploads/${data.id}`)
        .then(() => {
          const filesList = uploadedFiles.filter((file) => file.id !== data.id)
          setUploadedFiles(filesList);
          if (!filesList?.length) {
            setIsFileUploadError(true)
          }
        }).catch(() => {
          setAlert({
            open: true,
            variant: 'error',
          });
        })
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    setLoading(true);
    if (file.size > maxSize) {
      setIsTooBig(true);
      setLoading(false);
    }
    if (status === 'done') {
      let response = JSON.parse(xhr.response);
      Api.instance
        .post('/api/uploads-owner', {
          id: response?.item?.id,
          type: types[type],
          type_id: componentId
        })
        .then(({ data: { item } }) => {
          setUploadedFiles((prev) => ([...prev, item]))
          if (item) {
            setIsFileUploadError(false)
            setChangeError(prev => !prev)
          }
        }).catch(() => {
          setAlert({
            open: true,
            variant: 'error',
          });
        });
      setIsTooBig(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uploads) {
      setUploadedFiles(uploads);
    }
  }, [uploads])

  useEffect(() => {
    setIsFileUploadError(false)
  }, [])

  const Preview = ({meta, fileWithMeta}) => {
    const { name, percent, status } = meta;
    return (
      <div className={styles.custom_preview}>
        <div>
          <span className="name">{name}</span> -
          <span className="status">{status}</span>
          {status !== "done" &&
            <span className="percent"> ({Math.round(percent)}%)</span>
          }
        </div>
        <img src="/images/delete.svg" onClick={() => fileWithMeta.remove()} />
      </div>
    )
  }

    const PreviewUploads = ({data, index}) => {
        const bytesToSize = (bytes) => {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }

        let mobileShortedName = data.name
        let shortedName = data.name
        let format = mobileShortedName.substring(mobileShortedName.length - 4)
        if (mobileShortedName.length > 17) {
          mobileShortedName = mobileShortedName.substring(0,14) + "...-" + format
        }
        if (shortedName.length > 55) {
          shortedName = shortedName.substring(0,52) + "...-" + format
        }

        return (
            <div className={styles.custom_preview}>
                <div>
                    <span className={styles.dataName} title={data.name}>{shortedName} - </span>
                    <span className={styles.mobileDataName} title={data.name}>{mobileShortedName} - </span>
                    <span className="status"> {bytesToSize(data.size)}</span>
                </div>
                <img src="/images/delete.svg" onClick={() => {
                    RemoveHandler(data);
                }} />
            </div>
        )
    }

  const Layout = ({input, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {

    return (
      <div>
        {uploadedFiles && uploadedFiles?.map((upload, index) => <PreviewUploads key={upload.id} index={index} data={upload} />)}

        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
          <div className={styles.upload_fill_label} onClick={openDialog}>
            <span>
              Drop your documents here!
            </span>
            {
              files.length < 1 &&
              <button>
                Upload Document
              </button>
            }

            <p>
              Acceptable file formats are jpg, jpeg, png, tiff, tif, esp and pdf. <br/>
              The size of each document shall not exceed 25MB.
            </p>
          </div>
          {loading && <h4>Uploading...</h4>}
        </div>

        {files.length > 0 && submitButton}
      </div>
    );
  };

  const openDialog = () => {
    document.getElementsByClassName("dzu-inputLabel")[0].click()
  }

  return (
        <div
          className={`${styles.upload_doc} ${type === "business" && isFileUploadError && styles.error_styles}`} id="drop_zone">
            <h4>{`Please upload ${asterix}`}</h4>
            {ubo ? (
              <ol>
                {
                  upload_type.map((el,ind) => (
                    <li key={ind}>{el}</li>
                  ))
                }
              </ol>
            ) : (
              <ul>
                {
                  upload_type.map((el,ind) => (
                    <li key={ind}>{el}</li>
                  ))
                }
              </ul>
            )}
            <Dropzone
              multiple
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              accept=".jpg,.jpeg,.png,.tiff,.tif,.esp,.pdf"
              LayoutComponent={Layout}
              inputContent={null}
              PreviewComponent={Preview}
              maxSizeBytes={1024 * 25600}
              styles={{
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                dropzone: {
                  marginTop: '24px',
                  background: '#f6f7f8',
                  border: '2px dashed #566171',
                  height: 'auto',
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  overflow: 'hidden'
                },
                inputLabelWithFiles: {
                  background: '#FFFFFF',
                  border: '1px solid rgba(21, 23, 36, 0.5)',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  fontSize: '14px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '28%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  alignItems: 'center',
                  color: '#151724',
                  marginTop: '0',
                  marginLeft: '0',
                  zIndex: '2',
                  minHeight: '25px'
                }
              }}
            />
            {isTooBig && <p className={styles.error}>The size of each document should not exceed 25MB</p>}
            {type === "business" && isFileUploadError && <p className={styles.required_error}>Required</p>}
        </div>
    )
}

export default FpFile;
