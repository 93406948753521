import styles from "./FpInput.module.scss";
import cn from "classnames";
import React, { useState } from "react";

const FpInput = ({
  name,
  placeholder,
  type = "text",
  defaultValue = "",
  onChange = (target) => {},
  className,
  id,
}) => {
  const [state, setState] = useState(true);
  return (
    <label className={`${styles.text_input_label} ${className}`}>
      <p
        className={
          state && !defaultValue?.toString().length
            ? styles.input_placeholder
            : styles.input_placeholder_not_empty
        }
      >
        {placeholder}
      </p>
      <div>
        <input
          id={id}
          type={type}
          autoComplete="off"
          value={defaultValue}
          defaultValue={defaultValue}
          onFocus={() => setState(false)}
          onWheel={(e) => {
            e.currentTarget.type === 'number' && e.currentTarget.blur()
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              setState(true);
            } else if (state) {
              setState(false);
            }
          }}
          onChange={(e) => {
            if (!e.target.value) {
              onChange(null)
            } else {
              onChange(e.target.value.replace(/  +/g, ' ').trimStart());
            }
          }}
          name={name}
        />
      </div>
    </label>
  );
};

export default FpInput;
