import React, { useEffect, useState } from "react";
import styles from "./FpSelect.module.scss"
import Select from "react-select"

const FpSelect = ({
    name,
    placeholder,
    className,
    title = '',
    id,
    defaultValue,
    onChange = target => {},
    data
}) => {
  const [value, setValue] = useState(defaultValue)
  const [bordered, setBordered] = useState(false);
  useEffect(() => {setValue(defaultValue)}, [defaultValue])

  const style = {
    option: (styles) => {
        return {
          ...styles,
          color: "#333333",
          background: '#fff',
          "&:hover": {
            background: "#ced5dc !important",
            "div" : {
              background: "#ced5dc !important",
            }
          },
        };
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            padding: "6px 10px",
        }
    },
    dropdownIndicator: (styles, state ) => {
      state.selectProps.menuIsOpen ? setBordered(true) : setBordered(false);
      return {
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        marginTop: "-20px",
        color: "#151724"
      }
    },
    control: (styles) => {
      return {
        ...styles,
        background: '#f6f7f8'
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginTop: '20px',
        width: 'calc(100% + 4px)',
        boxShadow: "0px 8px 10px #dedada, -10px 8px 15px #dedada, 10px 8px 15px #dedada !important",
        background: '#fff',
        marginLeft: "-2px"
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 333,
      }
    }
  }

  return (
        <label className={`${styles.select_label} ${className} ${
            bordered && styles.bordered
          }`} id={id}>
            <span className="select_title">{title}</span>
            <Select
                placeholder={placeholder || "Select"}
                className={styles.country_select}
                options={data}
                value={data?.find(el => el?.label === value)}
                option={value}
                onChange={(e) => {
                    setValue(e.value)
                    onChange(e)
                  }}
                isSearchable={false}
                styles={style}
            />
        </label>
    )
}

export default FpSelect