import React, { useEffect } from "react";
import styles from "./style.module.scss"
import CompanyDetails from "./CompanyDetails";
import BusinessInfo from "./BussinesInfo";
import AquiringInfo from "./AquiringInfo";
import SettlementInfo from "./SettlementInfo";

const Questionnaire = () => {
    return (
        <div className={styles.questionnaire} id="questionnaire_a">
            <h2 id='questionnaire'>1. Questionnaire</h2>
            <span>Please provide us with the following information</span>
            <div className="content">
                <CompanyDetails />
                <BusinessInfo />
                <AquiringInfo />
                <SettlementInfo />
            </div>
        </div>
    )
}

export default Questionnaire