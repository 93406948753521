import React   from "react";
import { Field } from 'react-final-form'
import FpTextArea from "./FpTextArea/FpTextArea";
import styles from "./style.module.scss"

class TextArea extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Field {...this.props} >
                {props => (
                    <div className={styles.text_area}>
                        <FpTextArea
                            reactForm={true}
                            placeholder={this.props.placeholder}
                            defaultValue={props.input.value}
                            onChange={props.input.onChange}
                            name={props.input.name}
                            className={
                                props.meta.error && props.meta.touched && styles.validator_error_style
                            }
                        />
                        {props.meta.error && props.meta.touched && (
                            <p className={styles.validator_text}>{props.meta.error}</p>
                        )}
                    </div>
                )}
            </Field>
        )
    }
}

export default TextArea;