import styles from "./FpInput.module.scss";
import React, { useEffect, useState } from "react";

const FpNumberInput = ({
  name,
  placeholder,
  defaultValue,
  onChange = (target) => {},
  className,
  id,
  requiredMsg,
  type = "number"
}) => {
  const [state, setState] = useState(true);
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(defaultValue ? Math.abs(defaultValue) : "")
  },[defaultValue])

  return (
    <label className={`${styles.text_input_label} ${className}`}>
      <p
        className={
          state && !value?.toString().length
            ? styles.input_placeholder
            : styles.input_placeholder_not_empty
        }
      >
        {placeholder}
      </p>
      <div>
        <input
          id={id}
          type={type}
          value={value}
          autoComplete="off"
          defaultValue={value}
          onFocus={() => setState(false)}
          onBlur={(e) => {
            if (e.target.value === "") {
              setState(true);
            } else if (state) {
              setState(false);
            }
          }}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => {
            if (!e.target.value) {
              onChange(null)
            } else {
              onChange(e);
            }
          }}
          name={name}
        />
        {requiredMsg && <span>{requiredMsg}</span>}
      </div>
    </label>
  );
};

export default FpNumberInput;
