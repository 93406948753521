export const COMPANY_DETAILS_INITIAL_VALIDATION_RULES = {
    name: "required:string",
    registration_no: "required:string",
    registration_date: "required:timestamp",
    tax_country: "required:string",
    laRegCountry: "required:string",
    legalAddress: "required:string",
    laPostcode: "required:string",
    officeAddress: "required:string",
    oaPostcode: "required:string",
    phone_country: "required:phone",
    phone: "required:string",
    email: "required:email",
    website: "required:website",
    need_license: "required:string",
    business_description: "required:string"
};

export const COMPANY_DETAILS_ADDITIONAL_VALIDATION_RULES = {
    licIssAuthority: "required:string",
    licIssCountry: "required:string",
    licIssDate: "required:timestamp",
    licNumber: "required:string",
};

export const BUSINESS_INFO_VALIDATION_RULES = {
    domains: "required:string",
    domain_owner: "required:string",
    level: "required:number",
    pci_saq: "required:timestamp",
    pci_asv: "required:timestamp",
    isFileUploaded: "required:boolean"
};

export const SETTLEMENT_INFO_VALIDATION_RULES = {
    "bank_name": "required:string",
    "swift_code": "required:string",
    "iban": "required:string",
}

export const BENEFICIAL_OWNER_VALIDATION_RULES = {
    firstName: "required:string",
    lastName: "required:string",
    cob: "required:string",
    birthday: "required:timestamp",
    citizenship: "required:string",
    passportId: "required:string",
    issCountry: "required:string",
    issAuthority: "required:string",
    issDate: "required:timestamp",
    validTill: "required:timestamp",
    taxResidencyCountry: "required:string",
    residenceAddr: "required:string",
    raPostcode: "required:string",
    phoneCode: "required:phone",
    contactPhone: "required:string",
    email: "required:email",
    pep: "required:string",
    relatedUS: "required:string",
    loundert: "required:string",
    criminal: "required:string",
    sanction: "required:string",
};

export const SHAREHOLDER_COMPANY_VALIDATION_RULES = {
    laRegCountry: "required:string",
    companyName: "required:string",
    regNumber: "required:string",
    ownership: "required:string",
    legalAddress: "required:string",
    laPostcode: "required:string",
    officeAddress: "required:string",
    oaPostcode: "required:string",
    phoneCode: "required:phone",
    contactPhone: "required:string",
    email: "required:email",
};

export const SHAREHOLDER_PERSON_VALIDATION_RULES = {
    firstName: "required:string",
    lastName: "required:string",
    cob: "required:string",
    dob: "required:timestamp",
    citizenship: "required:string",
    passportId: "required:string",
    issCountry: "required:string",
    issAuthority: "required:string",
    issDate: "required:timestamp",
    validTill: "required:timestamp",
    residenceAddr: "required:string",
    taxResidencyCountry: "required:string",
    postcode: "required:string",
    phoneCode: "required:phone",
    contactPhone: "required:string",
    email: "required:email",
    relationUS: "required:string",
    loundert: "required:string",
    criminal: "required:string",
    sanction: "required:string",
    pep: "required:string",
};

export const ACQUIRING_INITIAL_VALIDATION_RULES = {
    types: "required:array",
    default_currencies: "required:array",
}

export const ACQUIRING_E_COMMERCE_VALIDATION_RULES = {
    minTransAmount: "required:string",
    averageTransAmount: "required:string",
    maxTransAmount: "required:string",
    planMonthTurnIn3Month: "required:string",
    planMonthTurnAfter3Month: "required:string"
};

export const ACQUIRING_OCT_VALIDATION_RULES = {
    octMinTransAmount: "required:string",
    octAverageTransAmount: "required:string",
    octMaxTransAmount: "required:string",
    octPlanMonthTurnBefore3Month: "required:string",
    octPlanMonthTurnAfter3Month: "required:string",
}

export const MANAGEMENT_VALIDATION_RULES = {
    firstName: "required:string",
    lastName: "required:string",
    cob: "required:string",
    dob: "required:timestamp",
    citizenship: "required:string",
    passportId: "required:string",
    issCountry: "required:string",
    issAuthority: "required:string",
    issDate: "required:timestamp",
    validTill: "required:timestamp",
    residenceAddr: "required:string",
    taxResidencyCountry: "required:string",
    postcode: "required:string",
    phoneCode: "required:phone",
    contactPhone: "required:string",
    email: "required:email",
    pep: "required:string",
    relationUS: "required:string",
    loundert: "required:string",
    crim: "required:string",
    sanction: "required:string",
};