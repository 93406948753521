import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import styles from "./BeneficialOwner.module.scss";
import FpBenefitInput from "./components/FpInput/FpBenefitInput";
import FpBenefitNumberInput from "./components/FpInput/FpBenefitNumberInput";
import FpCheck from "../UI/FpCheck/FpCheck";
import FpFile from "../UI/FpFile/FpFile";
import FpRadio from "../UI/FpRadio";
import FpCountry from "./components/FpCountry/FpCountry";
import FpPhone from "./components/FpPhone/FpPhone";
import SrcOfFundsButtons from "./SrcOfFundsButtons";
import FpTextArea from "./components/FpTextArea/FpTextArea";
import FpDatePicker from "../UI/FpDatePicker/FpDatePicker";
import AutoSave from "../../Helpers/AutoSave";
import {Form, Field} from "react-final-form";
import useStore from "../../contexts/context";
import errorStyles from "./components/style.module.scss";
import {getCurrentDate, getDate18YearsAgo} from "../../utils/DateUtils";
import * as Api from '../../utils/api'
import { isRequired, isEmailValidation, getValidationRules } from "../../utils/validation";
import { getDataWithDuplicates } from "../../utils/getCountriesArray";
import useShareholderStore from "../../contexts/shareholderContext";
import { BENEFICIAL_OWNER_VALIDATION_RULES } from "../../validation/formValidation";
import { getValidationPercent } from "../../Helpers/ComponentValidator";
import {validEmail} from "../../Helpers/FormValidator"

const BeneficialOwner = ({
 uboId,
 deleteUBO,
 isMany,
 copyShareholder = false,
}) => {
    const { slug } = useParams();
    let formActualValues = {};
    const {readySubmit, setReadySubmit, setLoader } = useStore();
    const [error, setError] = useState({})
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [form, setForm] = useState(null);
    const [samePerson, setSamePerson] = useState(false);
    const { setShareholderState } = useShareholderStore()

  function formValuesDidChanged(formValues) {
      formActualValues = formValues;
  };

  const filesFromShareholder = (isChecked) => {
    const data = {
      form_url: slug,
      shareholderId: form?.shareholder_id,
      typeId: uboId,
      type: 2,
    };

    if (isChecked) {
      return Api.instance.post('/api/uploads/share-files', { ...data });
    } else {
      return Api.instance.delete(`/api/uploads/share-files/2/${uboId}`);
    }
  };

  const sameOwner = () => {
    setLoader(true);
    Api.instance
      .get(`/api/application/shareholder/${form?.shareholder_id}`)
      .then(({ data: { item } }) => {
        const checked = !samePerson;
        setSamePerson(checked);
        const copyForm = { ...formActualValues };
        Object.keys(item).map((copy) => {
          if (copy !== 'beneficial_owner') {
              if (copy === 'dob') {
                  copyForm.birthday = checked ? item[copy] : '';
              } else if (copy === 'issDate') {
                  copyForm.issDate = checked ? item[copy] : '';
              } else if (copy === 'postcode') {
                  copyForm.raPostcode = checked ? item[copy] : '';
              } else if (copy === 'sof') {
                  copyForm.srcOfFunds = checked ? item[copy] : [];
              } else if (copy === 'otherSof') {
                  copyForm.srcOfFundsOther = checked ? item[copy] : '';
              } else if (copy === 'relationUS') {
                  copyForm.relatedUS = checked ? item[copy] : '';
              } else if (copy === 'id') {
                  copyForm.id = form.id
              } else {
                  copyForm[copy] = checked ? item[copy] : '';
              }
          };
        });

        filesFromShareholder(checked).then(() => {
          if (checked) {
            Api.instance.get(`/api/get-files/2/${uboId}`)
              .then(({ data }) => {
                setForm({ ...item, ...copyForm, uploads: data?.items });
                setLoader(false);
              })
          } else {
            setForm({ ...item, ...copyForm, uploads: [] });
            setLoader(false);
          }
        })

    })
      .catch(() => setLoader(false));
  };

  const changeBeneficialOwnerData = async (values) => {
      await Api.instance.put(`/api/application/ubo/${uboId}`, {
          ...values
      })
  }

  const setBeneficialOwnerName = (valuesChanged) => {
    if (Object.keys(valuesChanged).length === 1) {
        if (valuesChanged.hasOwnProperty('firstName') || valuesChanged.hasOwnProperty('lastName')) {
          setShareholderState((prev) => {
            return prev.map((elem) => {
              if (elem?.id === form?.shareholder_id) {
                return {
                  ...elem,
                  beneficial_owner: elem?.beneficial_owner?.map((el) => {
                    if (el?.id === form?.id) {
                      return {
                        ...el,
                        firstName: formActualValues?.firstName,
                        lastName: formActualValues?.lastName
                      }
                    } else {
                      return el
                    }
                  })
                }
              } else {
                return elem
              }
            })
          })
        }
      }
  };


  const AutoSaver = async (values, valuesChanged) => {
      const validationRules =  getValidationRules(values, BENEFICIAL_OWNER_VALIDATION_RULES);
      if (
        Object.keys(valuesChanged).length === 1 &&
        valuesChanged.hasOwnProperty('email')
      ) {
         return await isEmailValidation(valuesChanged, setError, changeBeneficialOwnerData, values)
      }

      if (
        Object.keys(valuesChanged).length === 1 &&
        valuesChanged.hasOwnProperty('taxResidencyCountry')
      ) {
        const autoFillValue = getDataWithDuplicates.find(element => element.iso3 === valuesChanged?.['taxResidencyCountry']);
        const actualValues = { ...values, phoneCode: autoFillValue.value }
        setForm(actualValues);
        return changeBeneficialOwnerData(actualValues)
      }
        setError({})
        setBeneficialOwnerName(valuesChanged)
        setShareholderState((prev) => {
          return prev.map((elem) => {
            if (elem?.id === form?.shareholder_id) {
              return {
                ...elem,
                beneficial_owner: elem?.beneficial_owner?.map((el) => {
                  if (el?.id === form?.id) {
                    return {
                      ...el,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      isValid: getValidationPercent(values, validationRules) === 100
                    }
                  } else {
                    return el
                  }
                })
              }
            } else {
              return elem
            }
          })
        })
        await changeBeneficialOwnerData(values);
        await sleep(1000);
  };

    const onSubmit = async () => {
        await setReadySubmit({...readySubmit, shareholder: true});
    };

    useEffect(() => {
      if (uboId) {
        Api.instance.get(`/api/application/ubo/${uboId}`)
          .then(({data: {items}}) => setForm(items))
      }
    }, [uboId])

    useEffect(() => {
        if (form?.shareholder_id && copyShareholder) {
            Api.instance
              .get(`/api/application/shareholder/${form?.shareholder_id}`)
              .then(({ data: { item } }) => {
                  if (item.passportId) {
                      const check = item.passportId === form?.passportId;
                      setSamePerson(check);
                  }
              });
        }
    }, [form?.shareholder_id])

    return (
        <>
      <span className={styles.ubo_title}>
        Please provide information about beneficial owner of the applicant
      </span>
            <div className={styles.beneficial} id={`ubo_${uboId}`}>
                <div className={`select_box hide ${styles.beneficial_title}`}>
                    <div className={styles.left_side}>
                        <img src="/images/shareHolders.svg" alt=""/>
                        <p>Ultimate Beneficial Owner</p>
                    </div>
                    <div className={styles.right_side}>
                        {isMany && (
                          <span
                            className={styles.deleteButton}
                            onClick={() => deleteUBO(form, uboId)}
                          >
                              Delete
                          </span>
                        )}
                        <p className={styles.percent}>{parseInt(form?.ownership) || 0}%</p>
                    </div>
                </div>
                <Form
                    onSubmit={onSubmit}
                    initialValues={form}
                    subscription={{}}
                    className="hide"
                >
                    {({handleSubmit}) => (
                        <form className={"submittableForm"} onSubmit={handleSubmit}>
                            <button
                                style={{display: "none"}}
                                className={`doSubmit`}
                                type="submit"
                            >
                                Submit
                            </button>
                            <AutoSave debounce={500} save={AutoSaver} valuesDidChanged={formValuesDidChanged}/>
                            {form?.kind === 'individual' && copyShareholder && (
                              <FpCheck
                                checked={samePerson}
                                onChange={() => {
                                    sameOwner();
                                }}
                                title="Ultimate Beneficial Owner is same as Shareholder"
                                className={styles.checkbox}
                              />
                            )}

                            <div className={styles.justify_center_section}>
                                <Field name="firstName" validate={isRequired}>
                                    {(props) => (
                                        <div className={styles.justify_center_section_first}>
                                            <FpBenefitInput
                                                type="text"
                                                defaultValue={props.input.value}
                                                name={props.input.name}
                                                onChange={props.input.onChange}
                                                placeholder="First Name *"
                                            />
                                            {props.meta.error && props.meta.touched && (
                                                <span className={errorStyles.validator_text}>{props.meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                                <Field name="lastName" validate={isRequired}>
                                    {(props) => (
                                        <div className={styles.justify_center_section_second}>
                                            <FpBenefitInput
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                type="text"
                                                name={props.input.name}
                                                placeholder="Last Name *"
                                            />
                                            {props.meta.error && props.meta.touched && (
                                                <span className={errorStyles.validator_text}>
                                                    {props.meta.error}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section_person}>
                                <Field name="cob" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpCountry
                                                placeholder="Country of birth *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="birthday" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpDatePicker
                                                isUBODatePicker
                                                maxDate={getDate18YearsAgo()}
                                                className="UBO_data_label"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                placeholder="Date of birth *"
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section}>
                                <Field name="citizenship" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpCountry
                                                placeholder="Citizenship *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="passportId" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                type="text"
                                                placeholder="Passport/ID card No *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section}>
                                <Field name="issCountry" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpCountry
                                                placeholder="Issuing country *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="issAuthority" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                placeholder="Issuing authority *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section_person}>
                                <Field name="issDate" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpDatePicker
                                                isUBODatePicker
                                                className="UBO_data_label"
                                                placeholder="Date of issue *"
                                                maxDate={getCurrentDate()}
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="validTill" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpDatePicker
                                                isUBODatePicker
                                                className="UBO_data_label"
                                                placeholder="Valid till *"
                                                minDate={getCurrentDate()}
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section}>
                                <Field name="taxResidencyCountry" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpCountry
                                                placeholder="Tax residence country *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="taxpayerId">
                                    {(props) => (
                                        <FpBenefitInput
                                            placeholder="Taxpayer ID (if available)"
                                            defaultValue={props.input.value}
                                            onChange={props.input.onChange}
                                            name={props.input.name}
                                            error={props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className={styles.justify_center_section_person}>
                                <Field name="residenceAddr" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                placeholder="Residence address *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="raPostcode" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                placeholder="Postcode *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.display_flex_reverse_section}>
                                <Field name="phoneCode" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpPhone
                                                placeholder="Phone code *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="contactPhone" validate={isRequired}>
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                placeholder="Phone number *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                type="number"
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>
                            <div>
                                <Field
                                  type="email"
                                  name="email"
                                  validate={validEmail}
                                >
                                    {(props) => (
                                        <React.Fragment>
                                            <FpBenefitInput
                                                type="email"
                                                placeholder="Email *"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                <Field name="srcOfFunds">
                                    {(props) => (
                                        <React.Fragment>
                                            <SrcOfFundsButtons
                                                values={props.input.value}
                                                changeEvent={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                                onClick={() => {
                                                    let arr = formActualValues?.srcOfFunds ? [...formActualValues?.srcOfFunds] : []
                                                    let string = 'Other (please specify)'
                                                    const formUpdatedValues = {};
                                                    if (arr.includes(string)) {
                                                        let unCheckedArr = arr.filter((item) => item !== string)
                                                        Object.assign(formUpdatedValues, {
                                                            ...formActualValues,
                                                            srcOfFunds: unCheckedArr
                                                        })
                                                    } else {
                                                        arr.push(string)
                                                        Object.assign(formUpdatedValues, {
                                                            ...formActualValues,
                                                            srcOfFunds: arr
                                                        })
                                                    }
                                                    setForm(formUpdatedValues);
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                                {form?.srcOfFunds?.includes("Other (please specify)") && (
                                    <Field name="srcOfFundsOther">
                                        {(props) => (
                                            <FpTextArea
                                                placeholder="Other sources of funds"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>

                            <div>
                                <div className="radio_component">
                                  <span className={styles.section_title}>
                                    How does Beneficial owner own/control capital shares / shares with the voting rights?
                                  </span>
                                    <div className={styles.radio_section}>
                                        <Field name="directoryShares">
                                            {(props) => (
                                                <React.Fragment>
                                                    <FpRadio
                                                        options={[{yes: "Directly"}, {no: "Indirectly"}]}
                                                        title={["Directly", "Indirectly"]}
                                                        className="container"
                                                        defaultValue={props.input.value}
                                                        onChange={props.input.onChange}
                                                        name={props.input.name}
                                                        onClick={e => {
                                                            setForm({
                                                                ...formActualValues,
                                                                directoryShares: e.target.value
                                                            })
                                                        }}
                                                    />
                                                    {props.meta.error && props.meta.touched && (
                                                        <span className={errorStyles.validator_text_radio}>
                                                          {props.meta.error}
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                {form?.directoryShares === "yes" && (
                                    <Field name="ownership">
                                        {(props) => (
                                            <FpBenefitNumberInput
                                                className={`ownership_input`}
                                                placeholder="% capital shares"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                type="number"
                                            />
                                        )}
                                    </Field>
                                )}
                              {form?.directoryShares === "no" && (
                                <Field name="votingRights">
                                  {(props) => (
                                    <FpTextArea
                                      placeholder="Please explain"
                                      defaultValue={props.input.value}
                                      onChange={props.input.onChange}
                                      name={props.input.name}
                                      error={props.meta.touched && props.meta.error}
                                    />
                                  )}
                                </Field>
                              )}

                                <div className="radio_component">
                                  <span className={styles.section_title}>
                                    Is this person a Politically Exposed Person, Relative and
                                    Close Associate? *
                                  </span>
                                    <div className={styles.radio_section}>
                                        <Field name="pep" validate={isRequired}>
                                            {(props) => (
                                                <React.Fragment>
                                                    <FpRadio
                                                        options={[{no: "No"}, {yes: "Yes"}]}
                                                        title={["No", "Yes"]}
                                                        className="container"
                                                        defaultValue={props.input.value}
                                                        onChange={props.input.onChange}
                                                        name={props.input.name}
                                                        onClick={e => {
                                                            setForm({...formActualValues, pep: e.target.value})
                                                        }}
                                                    />
                                                    {props.meta.error && props.meta.touched && (
                                                        <span className={errorStyles.validator_text_radio}>
                                                          {props.meta.error}
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                {form?.pep === "yes" && (
                                    <Field name="pepDescription" validate={isRequired}>
                                        {(props) => (
                                            <FpTextArea
                                                placeholder="Please explain"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>

                            <div className="radio_component">
                                <span className={styles.section_title}>
                                  Is this person related with the US? *
                                </span>
                                <div className={styles.radio_section}>
                                    <Field name="relatedUS" validate={isRequired}>
                                        {(props) => (
                                            <React.Fragment>
                                                <FpRadio
                                                    options={[{no: "No"}, {yes: "Yes"}]}
                                                    title={["No", "Yes"]}
                                                    className="container"
                                                    defaultValue={props.input.value}
                                                    onChange={props.input.onChange}
                                                    name={props.input.name}
                                                    onClick={e => {
                                                        setForm({...formActualValues, relatedUS: e.target.value})
                                                    }}
                                                />
                                                {props.meta.error && props.meta.touched && (
                                                    <span className={errorStyles.validator_text_radio}>
                                                        {props.meta.error}
                                                    </span>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="grid-1 mb-1">
                                {form?.relatedUS === "yes" && (
                                    <Field name="taxIdNumber">
                                        {(props) => (
                                            <FpBenefitInput
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                placeholder="Tax identification No"
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>

                            <div className="radio_component">
                                <span className={styles.section_title}>
                                  Was Beneficial Owner involved in money laundering activities any time in the past? *
                                </span>
                                <div className={styles.radio_section}>
                                    <Field name="loundert" validate={isRequired}>
                                        {(props) => (
                                            <React.Fragment>
                                                <FpRadio
                                                    options={[{no: "No"}, {yes: "Yes"}]}
                                                    title={["No", "Yes"]}
                                                    className="container"
                                                    defaultValue={props.input.value}
                                                    onChange={props.input.onChange}
                                                    name={props.input.name}
                                                    onClick={e => {
                                                        setForm({...formActualValues, loundert: e.target.value})
                                                    }}
                                                />
                                                {props.meta.error && props.meta.touched && (
                                                    <span className={errorStyles.validator_text_radio}>
                                                        {props.meta.error}
                                                    </span>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className="grid-1">
                                {form?.loundert === "yes" && (
                                    <Field name="loundertDescription" validate={isRequired}>
                                        {(props) => (
                                            <FpBenefitInput
                                                placeholder="Please explain"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>

                            <div className="radio_component">
                                <span className={styles.section_title}>
                                  Was Beneficial Owner a subject to criminal prosecution? *
                                </span>
                                <div className={styles.radio_section}>
                                    <Field name="criminal" validate={isRequired}>
                                        {(props) => (
                                            <React.Fragment>
                                                <FpRadio
                                                    options={[{no: "No"}, {yes: "Yes"}]}
                                                    title={["No", "Yes"]}
                                                    className="container"
                                                    defaultValue={props.input.value}
                                                    onChange={props.input.onChange}
                                                    name={props.input.name}
                                                    onClick={e => {
                                                        setForm({...formActualValues, criminal: e.target.value})
                                                    }}
                                                />
                                                {props.meta.error && props.meta.touched && (
                                                    <span className={errorStyles.validator_text_radio}>
                                                        {props.meta.error}
                                                    </span>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="grid-1">
                                {form?.criminal === "yes" && (
                                    <Field name="crimDescription" validate={isRequired}>
                                        {(props) => (
                                            <FpTextArea
                                                placeholder="Please explain"
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>

                            <div className="radio_component">
                                <span className={styles.section_title}>
                                  Has Beneficial Owner been sanctioned by any official or financial regulatory bodies? *
                                </span>
                                <div className={styles.radio_section}>
                                    <Field name="sanction" validate={isRequired}>
                                        {(props) => (
                                            <React.Fragment>
                                                <FpRadio
                                                    options={[{no: "No"}, {yes: "Yes"}]}
                                                    title={["No", "Yes"]}
                                                    className="container"
                                                    defaultValue={props.input.value}
                                                    onChange={props.input.onChange}
                                                    name={props.input.name}
                                                    onClick={e => {
                                                        setForm({...formActualValues, sanction: e.target.value})
                                                    }}
                                                />
                                                {props.meta.error && props.meta.touched && (
                                                    <span className={errorStyles.validator_text_radio}>
                                                        {props.meta.error}
                                                    </span>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="grid-1">
                                {form?.sanction === "yes" && (
                                    <Field name="sanctionDescription" validate={isRequired}>
                                        {(props) => (
                                            <FpTextArea
                                                defaultValue={props.input.value}
                                                onChange={props.input.onChange}
                                                name={props.input.name}
                                                placeholder="Please explain"
                                                className={styles.text_fild}
                                                error={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                )}
                            </div>
                            <FpFile
                                uploads={form?.uploads}
                                name="personUploads"
                                title={"Drop your documents here!"}
                                type='ubo'
                                componentId={form?.id}
                                ubo={true}
                                upload_type={[
                                  "Beneficial Owner Declaration / Declaration of Trust / Register of Persons with Significant Control",
                                  "Passport or ID card",
                                  "Proof of residential address (utility bill, bank statement, bank reference letter)",
                                ]}
                            />
                        </form>
                    )}
                </Form>
            </div>
        </>
    );
};

export default BeneficialOwner;
