import React, { useEffect, useState } from 'react';
import styles from './FpSelectButton.module.scss';
import Select from 'react-select';
import useStore from '../../../contexts/context';

const FpSelectButton = ({
                          placeholder,
                          title,
                          data,
                          onClick = () => {},
                          loading
                        }) => {
  const { setLoader } = useStore();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);

  const style = {
    option: (styles) => {
      return {
        ...styles,
        color: '#333333',
        background: '#fff',
        '&:hover': {
          background: '#ced5dc !important'
        }
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: '6px 10px'
      };
    },
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: '#151724'
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        width: 'calc(100% + 4px)',
        boxShadow:
          '0px 8px 10px #dedada, -10px 8px 15px #dedada, 10px 8px 15px #dedada !important',
        marginLeft: '-2px',
        zIndex: 9999
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 333,
        background: '#fff'
      };
    }
  };

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <div className={styles.flexContainer}>
      <label
        className={`${styles.select_label} ${
          error && styles.validator_error_select_style
        }`}>
        <span className="select_title">{title}</span>
        <Select
          isSearchable={false}
          placeholder={placeholder || 'Select'}
          value={selected}
          className={styles.country_select}
          options={data}
          styles={style}
          onChange={(e) => {
            setSelected(e);
            setError(false);
          }}
        />
      </label>
      {error && (
        <span className={styles.validator_text}>This field is required</span>
      )}
      <button
        className={` ${styles.add_owner}`}
        onClick={() => {
          if (selected?.value) {
            onClick(selected.value);
            setSelected(null);
          } else {
            setError(true);
          }
        }}
        disabled={loading}>
        Add
      </button>
    </div>
  );
};

export default FpSelectButton;