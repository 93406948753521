import React, { useState, createContext, useContext } from 'react';

export const DialogContext = createContext();

export const DialogStore = (props) => {
  const initialState = {
    open: false,
    handleEvent: () => {},
    buttonText: '',
    description: ''
  }
  const [dialogState, setDialogState] = useState(initialState)
  return (
    <DialogContext.Provider
      value={{
        dialogState,
        initialState,
        setDialogState
      }}>
      {props.children}
    </DialogContext.Provider>
  );
};

const useDialogStore = () => useContext(DialogContext);

export default useDialogStore;
