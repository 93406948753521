import { shareholderTypes } from './enums'

export const generateBiggerId = state => {
    if(!state.length) return 1
    const maxId = state.reduce(
        (max, state) => (state.id > max ? state.id : max),
        state[0].id
    )
    return maxId + 1
}

export const manipulateArrGroup = ({ key, arr }) => {
    const index = arr.indexOf(key)
    if(index === -1) {
        return [ ...arr, key ]
    } else {
        arr.splice(index,1)
        return [ ...arr]
    }
}

export const isEmpty = (value) =>
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length ===0 );


export const calculateGeneralSharhPercentage = ({ shareholders }) => {
    function myParseFloat(num) {
        if(num==='') return 0
        let val = parseFloat(num)
        return isNaN(val) ? 0 : val
    }
    if(!shareholders.length) return 0;
    let totalPercentage = 0;
    for(let shareholder of shareholders) {
        let currentPercentage = null;
        if(shareholder.type === shareholderTypes.CORPORATE) {
            currentPercentage = myParseFloat(shareholder.shareholderCompany.ownership)
        } else {
            currentPercentage = myParseFloat(shareholder.shareholderPerson.ownership)
        }
        totalPercentage += currentPercentage
    }
    return totalPercentage
}
