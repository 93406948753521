import React, { useState, useEffect } from "react";
import styles from "./FpSelect.module.scss";
import Select from "react-select";
import errorStyles from "../style.module.scss";
import { getUniqArray, getDataWithDuplicates } from "../../../../utils/getCountriesArray";

const FpPhone = ({
  placeholder,
  id = "",
  defaultValue = "",
  onChange = () => {},
  className,
  error,
}) => {
  const [value, setValue] = useState("");

  const dataWithoutDuplicates = getUniqArray(getDataWithDuplicates);

  const compare = ( a, b ) => {
    return a.value - b.value;
  }

  const getMatchingResult = (searchText) => {
    const searToLowerCase = searchText.toLowerCase();
    const filter = []
    getDataWithDuplicates.forEach((el) => {
      if (
        el.name.toLowerCase().includes(searToLowerCase) ||
        el.value?.toString().includes(searchText.toString()) ||
        el.iso3.toLowerCase().includes(searToLowerCase)
      ) {
        filter.push(el)
      }
    })
    return filter;
  };

  const countryCodesMatch = (searchText, countryCode) => {
    const similarCountries = getMatchingResult(searchText);
    let doMatch = false;
    similarCountries.forEach((el) => {
      if (el.value === countryCode){
        doMatch = true;
      }
    })
    return doMatch;
  }

  const customFilter = (option, searchText) => {
    return (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data.value?.toString().includes(searchText.toString()) ||
      option.data.iso3.toLowerCase().includes(searchText.toLowerCase()) ||
      countryCodesMatch(searchText, option.data.value)
    )
  };

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#333333",
        backgroundColor: isFocused ? "#F6F7F8" : isSelected ? "#ced5dc" : null,
        "&:hover": {
          background: "#F6F7F8 !important",
          div: {
            background: "#F6F7F8 !important",
          },
        },
        "&:active": {
          background: "#F6F7F8 !important",
        }
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
        zIndex: 99,
      }
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: 0,
        maxHeight: "22px",
        alignItems: "flex-end"
      };
    },
    control: (styles) => {
      return {
        ...styles,
        boxShadow: "unset",
      }
    },
    placeholder: (styles) => ({ ...styles, top: "50%", color: "#151724" }),
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: "#151724",
      };
    },
  };


  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <label className={`${styles.select_label} ${className}`} id={id}>
        {value && <p className={styles.date_placeholder_not_empty}>
          {placeholder}
        </p>}
        <Select
          onChange={(e) => {
            setValue(e.value);
            onChange(e.value);
          }}
          filterOption={customFilter}
          placeholder={placeholder}
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          options={dataWithoutDuplicates.sort(compare)}
          styles={colourStyles}
          value={dataWithoutDuplicates.find((el) => {
            if (el.value == value) {
              return value;
            }
          })}
          className={styles.country_select}
        />
      </label>
      {error && <span className={errorStyles.validator_text}>{error}</span>}
    </div>
  );
};

export default FpPhone;
