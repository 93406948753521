
export function getCurrentDate() {
    return new Date()
}

export function getDate18YearsAgo() {
    return getDateYearsAgo(18)
}

export function getDateYearsAgo(years) {
    const currentDate = getCurrentDate()

    return new Date(currentDate.getFullYear() - years, currentDate.getMonth(), currentDate.getDay())
}