import React, { useEffect, useState } from "react";
import styles from "../style.module.scss";

const FpRadio = ({
  name,
  className = "container",
  options = [],
  defaultValue = "",
  onChange = (target) => {},
  onClick = (target) => {},
  disabled = false,
  title = [],
  needToChangeValueImmediately = true,
  error,
}) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return options.map((option, i) => {
    let key = Object?.keys(option);
    return (
      <label className={`radio_label ${className} ${styles.shareholderTypeVhange}`} key={key}>
        {title[i]}
        <input
          autoComplete="off"
          value={key}
          key={key}
          id={key}
          type="radio"
          name={name}
          defaultChecked={key === value}
          disabled={disabled}
          onClick={e => {
            if (needToChangeValueImmediately) setValue(e.target.value);
              onClick(e)
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <span className={`checkmark ${(value && value.toLowerCase() == key) ? 'checked' : 'unchecked'}`}> </span>
      </label>
    );
  });
};

export default FpRadio;
