import React, { useState, useEffect } from "react";
import styles from "./FpSelect.module.scss"
import countries from "../../../countries";
import Select from 'react-select';

const FpCountry = ({
    title = '',
    id = '',
    defaultValue = '',
    onChange = () => {},
    className,
    reactForm = false,
}) => {
  const [value, setValue] = useState(defaultValue)

  const [bordered, setBordered] = useState(false);

  const data = countries.map((el) => {
    return {
      value: el.iso3,
      name: el.name,
      label: (
        <div>
          <img
            src={`/countries/${el.iso2.toLowerCase()}.svg`}
          />
          {el.name}
        </div>
      ),
    };
  });

  const customFilter = (option, searchText) => {
   if (option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.value.toLowerCase().includes(searchText.toLowerCase())) return true
    else return false
  }

  useEffect(() => {setValue(defaultValue)}, [defaultValue])
  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        padding: 12,
        color: "#333333",
        "&:hover": {
          background: "#ced5dc !important",
          "div" : {
            background: "#ced5dc !important",
          }
        },
        '&:active': {
          background: '#F6F7F8 !important'
        }
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: "5px 0px",
      }
    },
    placeholder: (styles) => {
      return {
        ...styles,
        marginLeft: "3px",
        color: "#151724"
      }
    },
    dropdownIndicator: (styles, state ) => {
      state.selectProps.menuIsOpen ? setBordered(true) : setBordered(false);
      return {
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        marginTop: "-30px",
        color: "#151724"
      }
    },
    control: (styles) => {
      return {
        ...styles,
        background: '#f6f7f8'
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: '-15px',
        width: 'calc(100% + 30px)',
        boxShadow: "0px 8px 10px #dedada, -10px 8px 15px #dedada, 10px 8px 15px #dedada !important",
        background: '#fff'
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: 333
      }
    }
  };

  return (
          <label className={`${styles.select_label} ${className} ${
            bordered && styles.bordered
          }`} id={id}>
            <span className="select_title">{title}</span>
            <Select
              onChange={(e) => {
                setValue(e.value)
                if (reactForm) {
                  onChange(e.value)
                } else onChange(e)
              }}
              onKeyDown={(e) => {
                setValue(e.target.value)
              }}
              filterOption={customFilter}
              placeholder="Choose Country"
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              options={data}
              styles={colourStyles}
              value={data.find(el => el.value === value)}
              className={styles.country_select}
              option={value}
            />
        </label>
    )
}

export default FpCountry
