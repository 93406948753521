import axios from "axios";
import {useState} from "react";
import config from '../config/server'
import {BackgroundSyncPlugin} from 'workbox-background-sync';
import {registerRoute} from 'workbox-routing';
import {NetworkOnly} from 'workbox-strategies';


if (navigator.workbox) {
    const bgSyncPlugin = new BackgroundSyncPlugin('FMPayApi', {
        maxRetentionTime: 5
    });

    registerRoute(
        /\/api\/.*\/*/,
        new NetworkOnly({
            plugins: [bgSyncPlugin]
        }),
        'POST'
    );
}

export const instance = axios.create({
    baseURL: config.baseUrl,
    timeout: 25000,
    headers: {'X-timezone': new Date().getTimezoneOffset()}
});

export const  Get = (path, query = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [started, setStarted] = useState(true);

    if (started){
        setStarted(false);
        instance.get(path, {
            params: query
        })
            .then(function (response) {
                setData(response.data)
            })
            .catch(function (error) {
                setData({
                    success: false,
                    message: error
                })
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return [data, loading];
}

export const  Delete = (path, query = {}) => {
    return instance.delete(path, {
        params: query
    });
}

export const  Put = (path, query = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [started, setStarted] = useState(true);

    if (started){
        setStarted(false);
        instance.put(path, query)
            .then(function (response) {
                setData(response)
            })
            .catch(function (error) {
                setData({
                    success: false,
                    message: error
                })
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return [data, loading];
}

export const  BackgroundPut = (path, query = {}) => {
    return instance.put(path, query);
}

export const  BackgroundPost = (path, query = {}) => {
    return instance.post(path, query);
}
