import React, { useState } from "react";
import styles from './FpTextArea.module.scss'
import cn from 'classnames'

const FpTextArea = ({
    name,
    placeholder,
    value = '',
    defaultValue = '',
    error = '',
    type = 'text',
    height = 'unset',
    onChange = () => {},
    className,
}) => {
  const [state, setState] = useState(true)
    return (
        <label className={`${styles.textarea_label} ${className}`}>
            <label className={styles.textarea_placeholder_not_empty}>{(state && !defaultValue?.length) ? null : placeholder}</label>
            <textarea
                type={type}
                defaultValue={defaultValue}
                value={defaultValue}
                name={name}
                style={{minHeight: height}}
                onFocus={() => setState(false)}
                onKeyUp={(e) => {
                  if (e.target.value === "") {
                    setState(true)
                  } else if (state) {
                    setState(false)
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setState(true)
                  }
                }}
                onChange={(e) => {
                  if (!e.target.value) {
                    onChange(null)
                  } else {
                    onChange(e.target.value.replace(/  +/g, ' ').trimStart());
                  }
                }}
                placeholder={placeholder}
            />
            {error && <span>{error}</span> }
        </label>
    )
}

export default FpTextArea
