import React, {useEffect, useState} from "react";
import styles from "./ShareholderCompany.module.scss";
import BeneficialOwner from "./BeneficialOwner";
import FpFile from "../UI/FpFile/FpFile";
import FpCheck from "../UI/FpCheck/FpCheck";
import { Form } from "react-final-form";
import AutoSave from "../../Helpers/AutoSave";
import useStore from "../../contexts/context";
import Country from "../UI/Country";
import Input from "../UI/Input";
import NumberInput from "../UI/NumberInput"
import PhoneCode from "../UI/PhoneCode";
import * as Api from "../../utils/api";
import useShareholderStore from "../../contexts/shareholderContext";
import useDialogStore from "../../contexts/dialogStore";
import useAlertContext from "../../contexts/alertContext";
import { SHAREHOLDER_COMPANY_VALIDATION_RULES } from "../../validation/formValidation";
import { getValidationPercent } from "../../Helpers/ComponentValidator";
import { isEmailValidation, isRequired } from "../../utils/validation";
import { validEmail, validWebsite } from "../../Helpers/FormValidator"
import Loader from "../Loader/Loader"
import config from '../../config/server'

const ShareholderCompany = ({
    index,
    shareholderId,
    calculateOwnershipsSum = () => {},
    getFullName = () => {}
}) => {
    let formActualValues = {};
    const [isChecked, setIsChecked] = useState(false);
    const [search, setSearch] = useState(null)
    const [ubo, setUbo] = useState([]);
    const [companies, setCompanies] = useState(null);
    const {readySubmit, setReadySubmit} = useStore();
    const { setDialogState } = useDialogStore();
    const { setAlert } = useAlertContext();
    const { setShareholderState } = useShareholderStore();
    const [error, setError] = useState({});
    const [sourceValue, setSourceValue] = useState({});
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({});
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const formValuesDidChanged = (formValues) => {
        formActualValues = formValues;
    };

    const approveToDelete = (id) => {
        Api.instance.delete(`/api/application/ubo/${id}`)
          .then(async () => {
            const {
                data: { items }
            } = await Api.instance.get(
              `/api/application/ubo?shareholder_id=${shareholderId}&type=corporate`
            );
            setShareholderState((prev) => {
                  return prev.map((elem) => {
                      if (elem.id === shareholderId) {
                          const banficialOwner = elem.beneficial_owner.filter((el) => el.id !== id)
                          return {
                              ...elem,
                              beneficial_owner: banficialOwner
                          }
                      } else {
                          return elem
                      }
                  })
              })
            setUbo(items);
            setAlert({
                open: true,
                variant: 'success',
                message: 'UBO was deleted successfully',
            });

        }).catch(() => {
            setAlert({
                open: true,
                variant: 'error',
            });
        });
    };

    const deleteBeneficialOwner = (elem) => {
        setDialogState((prev) => ({
            ...prev,
            open: true,
            description: `Do you want to remove ${
              elem?.firstName && elem?.lastName
                ? elem?.firstName + ' ' + elem?.lastName + '?'
                : 'Beneficial Owner?'
            }`,
            buttonText: 'Delete',
            handleEvent: () => approveToDelete(elem?.id)
        }));
    };

    const addNewBeneficialOwner = async () => {
        setLoading(true)
        Api.instance
          .post('/api/application/ubo', {
              shareholder_id: form?.id,
              type: 'corporate',
          })
          .then(async () => {
              const {
                  data: { items }
              } = await Api.instance.get(
                `/api/application/ubo?shareholder_id=${shareholderId}&type=corporate`
              );
              const newItem = items[items?.length - 1]
              setShareholderState((prev) => {
                  return prev.map((elem) => {
                      if (elem.id === shareholderId) {
                          return {
                              ...elem,
                              beneficial_owner: elem?.beneficial_owner?.length
                                ?  [...elem?.beneficial_owner, newItem]
                                : [newItem]
                          }
                      } else {
                          return elem
                      }
                  })
              })

              setUbo(items);
              setAlert({
                  open: true,
                  variant: 'success',
                  message: 'UBO was added successfully',
              });
          })
          .catch(() => {
              setAlert({
                  open: true,
                  variant: 'error',
              });
          })
          .finally(() => {
              setLoading(false)
          })
    };

    const arrOfficeAndLegalAddressesSame = (actualData) => {
        return (
          actualData?.officeAddress === actualData?.legalAddress &&
          actualData?.legalAddress !== "" &&
          actualData?.legalAddress !== null &&
          actualData?.oaPostcode === actualData?.laPostcode &&
          actualData?.laPostcode !== "" &&
          actualData?.laPostcode !== null
        )
    };

    const handleChecked = () => {
        setIsChecked(!isChecked);
        const laObj = {
          oaPostcode: !isChecked ? formActualValues?.laPostcode : null,
          officeAddress: !isChecked ? formActualValues?.legalAddress : null,
          office_address: {
            oaPostcode: !isChecked ? formActualValues?.laPostcode : null,
            officeAddress: !isChecked ? formActualValues?.legalAddress : null
          }
        };
        setForm({ ...formActualValues, ...laObj });
    };

    const changeShareholderData = async (values) => {
        await Api.instance.put(`api/application/shareholder/${shareholderId}`, {
            ...values
        })
    };

    const setShareholderName = (valuesChanged) => {
        if (
          Object.keys(valuesChanged).length === 1 &&
          valuesChanged.hasOwnProperty('companyName')
        ) {
            getFullName({
                kind: 'corporate',
                name: formActualValues?.companyName,
                id: shareholderId
            });

            setShareholderState((prev) => {
                return prev.map((elem) => {
                    if (elem?.id === shareholderId) {
                        return {
                            ...elem,
                            name: formActualValues?.companyName,
                        }
                    } else {
                        return elem
                    }
                })
            })
        }
    };

    const displayInvalidOwnershipAlert = () => {
        setDialogState((prev) => ({
            ...prev,
            cancelText: 'OK',
            open: true,
            description: `Summary ownership of all shareholders can not be greater than 100%.`
        }));
        setForm({
            ...formActualValues,
            ownership: '0'
        });
    };

    const validateOwnership = async (values) => {
        if (
          !calculateOwnershipsSum(shareholderId, formActualValues?.ownership) ||
          +formActualValues?.ownership > 100
        ) {
            displayInvalidOwnershipAlert();
        } else {
            await changeShareholderData(values)
        }
    };

    const executeCompanySearchIfNeeded = (userChanges) => {
        setCompanies(null);
        if (Object.keys(userChanges).length === 1 && userChanges.hasOwnProperty("companyName")) {
            setSearch(userChanges.companyName);
        }
    };

    const AutoSaver = async (values, valuesChanged) => {
        setForm(values)
        executeCompanySearchIfNeeded(valuesChanged)
        await sleep(1000);
        if (
          Object.keys(valuesChanged).length === 1 &&
          valuesChanged.hasOwnProperty('ownership')
        ) {
            await validateOwnership(values)
        } else if (
          Object.keys(valuesChanged).length === 1 &&
          valuesChanged.hasOwnProperty('email')
        ) {
            await isEmailValidation(valuesChanged, setError, changeShareholderData, values)
        } else if (
          (Object.keys(valuesChanged).length <= 2 && isChecked &&
            valuesChanged.hasOwnProperty('legalAddress')) ||
          (Object.keys(valuesChanged).length <= 2 && isChecked &&
            valuesChanged.hasOwnProperty('laPostcode'))
        ) {
            const actualValues = { ...values, officeAddress: values?.legalAddress, oaPostcode: values?.laPostcode };

            setForm(actualValues);
            await changeShareholderData(actualValues);
        } else if (
          Object.keys(valuesChanged).length === 1 &&
          (valuesChanged.hasOwnProperty('officeAddress') || valuesChanged.hasOwnProperty('oaPostcode'))
        ) {
            setIsChecked(arrOfficeAndLegalAddressesSame(values));
        } else {
            setShareholderName(valuesChanged)
            setShareholderState((prev) => {
                return prev.map((item) => {
                    if (item?.id === shareholderId) {
                        return {
                            ...item,
                            isValid: getValidationPercent(values, SHAREHOLDER_COMPANY_VALIDATION_RULES) === 100
                        }
                    } else {
                        return item
                    }
                })
            })
            await changeShareholderData(values);
        }
    };

    const onSubmit = async () => {
        await setReadySubmit({...readySubmit, shareholder: true});
    };

    const companyDataToShareholder = (company) => {
        let tmpForm = Object.assign({}, formActualValues);
        tmpForm.companyName = company?.name;
        tmpForm.regNumber = company?.registration_number;
        tmpForm.description = company?.description;
        tmpForm.laPostcode = company?.legal_address?.postcode;
        tmpForm.laRegCountry = 'GBR';
        tmpForm.legalAddress = company?.legal_address?.address;
        setForm(tmpForm)

        setCompanies(null);
    };

    useEffect(() => {
        if (formActualValues?.laRegCountry === "GBR" && search?.length > 1) {
            Api.instance
                .get(config.baseUrl + "/api/company_info?q=" + search)
                .then((res) => {
                    setCompanies(res.data.item);
                })
                .catch((e) => console.log(e));
        }
    }, [search]);

    useEffect(() => {
        if (shareholderId) {
            Api.instance.get(`api/application/shareholder/${shareholderId}`)
              .then(({ data: { item }  }) => {
                  setForm(item);
                  setIsChecked(arrOfficeAndLegalAddressesSame(item));
              })
            Api.instance.get(`/api/application/ubo?shareholder_id=${shareholderId}&type=corporate`)
              .then(({ data: { items }  }) => {
                  setShareholderState((prev) => {
                      return prev.map((elem) => {
                          if (items?.length !== elem.beneficial_owner?.length && elem.id === shareholderId) {
                              return {
                                  ...elem,
                                  beneficial_owner: items
                              }
                          } else {
                              return elem
                          }
                      })
                  })
                  setUbo(items)
              })
        }
    }, [shareholderId]);

    document.addEventListener("click", () => {
        setSearch(null);
        setCompanies(null)
    });

    return (
        <div>
            <Form
                onSubmit={onSubmit}
                initialValues={form}
                subscription={{}}
                className="hide"
            >
                {({handleSubmit}) => (
                        <form className={"submittableForm"} onSubmit={handleSubmit}>
                            <button
                                style={{display: "none"}}
                                className={`doSubmit`}
                                type="submit">
                                Submit
                            </button>
                        <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged}/>
                        <div className="hide">
                            <Country
                                placeholder="Registration country *"
                                name="laRegCountry"
                                validate={isRequired}
                                className={styles.input}
                                setSourceValue={setSourceValue}
                            />
                            <Input
                                placeholder="Company name *"
                                name="companyName"
                                validate={isRequired}
                                className={styles.input}
                            />
                            <div>
                                {companies && search && (
                                    <div className={styles.companies_div}>
                                        {companies?.map((company, index) => (
                                            <button
                                                key={index}
                                                className={styles.company_button}
                                                onClick={() => companyDataToShareholder(company)}
                                            >
                                                {company.name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <label className="grid_inputs">
                                <Input
                                    placeholder="Registration No *"
                                    name="regNumber"
                                    validate={isRequired}
                                />
                                <NumberInput
                                    placeholder="Ownership % *"
                                    name="ownership"
                                    validate={isRequired}
                                />
                            </label>
                            <span className={styles.section_title}>Legal address</span>
                            <div className={styles.display_flex_section}>
                                <Input
                                    placeholder="Legal address *"
                                    name="legalAddress"
                                    validate={isRequired}
                                />
                                <Input
                                    placeholder="Postcode *"
                                    name="laPostcode"
                                    validate={isRequired}
                                />
                            </div>
                            <span className={styles.section_title}>Office address</span>
                            <FpCheck
                                checked={isChecked}
                                onChange={handleChecked}
                                title="Office address is same as Legal address"
                            />
                            <div className={styles.display_flex_section}>
                                <Input
                                    placeholder="Office address *"
                                    name="officeAddress"
                                    validate={isRequired}
                                />
                                <Input
                                    placeholder="Postcode *"
                                    name="oaPostcode"
                                    validate={isRequired}
                                />
                            </div>
                            <span className={styles.section_title}>Contact details</span>
                            <div className={styles.display_flex_reverse_section}>
                                <PhoneCode
                                    placeholder="Phone code *"
                                    name="phoneCode"
                                    validate={isRequired}
                                    valueSource={sourceValue}
                                    setForm={setForm}
                                    form={form}
                                />
                                <Input
                                    placeholder="Phone number *"
                                    name="contactPhone"
                                    type="number"
                                    validate={isRequired}
                                />
                            </div>
                            <div className={styles.justify_center_section}>
                                <Input
                                  placeholder="Email *"
                                  name="email"
                                  validate={validEmail}
                                  error={error}
                                />
                                <Input
                                    placeholder="Website"
                                    name="website"
                                    validate={validWebsite}
                                />
                            </div>
                            <FpFile
                                uploads={form?.uploads || []}
                                type='shareholder'
                                componentId={shareholderId}
                                title={"Drop your documents here!"}
                                className={"border_none"}
                                upload_type={["Shareholder register / Register of Partners"]}
                            />
                        </div>
                    </form>
                )}
            </Form>
            {ubo?.map((row, pos) => {
                return (
                  <BeneficialOwner
                    key={pos}
                    index={index}
                    pos={pos}
                    uboId={row?.id}
                    deleteUBO={deleteBeneficialOwner}
                    isMany={ubo?.length > 1}
                  />
                );
            })}
            <button
                type={"button"}
                onClick={addNewBeneficialOwner}
                className={styles.add_owner}
                disabled={loading}
            >
                {loading && <span className={styles.loading_span}>
                    <Loader />
                </span>}
                <span>Add Additional Beneficial Owner</span>
            </button>
        </div>
    );
};

export default ShareholderCompany;
