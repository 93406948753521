import React from "react";
import styles from "./StartDoc.module.scss";

const StartDoc = () => {
  return (
    <>
      <div>
        <h2 className={styles.h2}>Before you start</h2>
        <div className={styles.start_doc}>
          <span>
            This questionnaire is a part of your service request. It enables us to evaluate your business
            requirements and offer you the best possible terms. It takes <span>approximately 10 minutes </span>
            to complete. Before submitting, you can return to this questionnaire any time in the next <span>14 days, </span> after which
            this questionnaire expires and becomes unavailable.
          </span>
          <br/>
          <br/>
          <span>
            This questionnaire is suitable for private limited companies and incorporated partnerships.
            If your business structure is not one of these contact us directly here: {' '}
            <a href="mailto:support@fmpay.co.uk">support@fmpay.co.uk</a>
          </span>
          <br/>
          <br/>
          <span>
            Please have high-quality scans of the following documents ready before you start your application:
          </span>
          <ol>
            <li>Certificate of Incorporation / Registration </li>
            <li>Memorandum and Articles of Association / ByLaws </li>
            <li>Register of Directors and Register of Shareholders (if applicable) </li>
            <li>Personal identification documents for all directors, shareholders and beneficial owners </li>
            <li>
              Residential address confirmation for all directors, shareholders and beneficial owners
            </li>
            <li>PCI DSS documents (AOC/SAQ + ASV scan results)</li>
            <li>Proof of domain ownership</li>
            <li>Business Bank statements for the last 3 months OR Processing history for the last 6 months </li>
            <li>Previous year’s financial statements OR Business plan in case it’s a startup</li>
          </ol>
          <span>
            For more information about required documents:
            <br />
            <a href="https://fmpay.co.uk/onboarding-documents/">
              {"https://fmpay.co.uk/onboarding-documents/"}
            </a>
          </span>
          <h4>What’s next?</h4>
          <span>
            Once you submit the questionnaire, it takes <span> 2-3 business days </span> to review your application
            and verify your documents. If all documents are in order, our customer onboarding
            team will contact you with the request to undergo our digital ID verification procedure.
          </span>
          <br/>
          <br/>
          <span>
            If you have any questions or experience technical difficulties with the form, please do not hesitate to contact us at {' '}
            <a href="mailto:support@fmpay.co.uk">support@fmpay.co.uk.</a>
          </span>
          <br/>
          <br/>
          <span>
            If you have any questions about your data please review our privacy policy here: {' '}
            <a href="https://fmpay.co.uk/policy-privacy/">https://fmpay.co.uk/policy-privacy/</a>
          </span>
        </div>
      </div>
    </>
  );
};

export default StartDoc;
