import styles from "./FpBenefitInput.module.scss";
import React, {useState, useEffect, useRef} from "react";
import errorStyles from "../style.module.scss";
const FpNumberInput = ({
                     name,
                     placeholder,
                     defaultValue,
                     type="number",
                     onChange = () => {
                     },
                     className,
                     error,
                 }) => {
    const [state, setState] = useState(true);
    const [value, setValue] = useState(null)
    useEffect(() => {
        setValue(defaultValue && typeof parseInt(defaultValue == Number) ? Math.abs(defaultValue) : "")
    },[defaultValue])
    const input = useRef();
    const focusEvent = () => {
        setState(false);
        input.current.focus();
    };
    return (
        <div>
            <label className={`${styles.text_input_label} ${className}`}>
                <p
                    className={
                        state && !defaultValue?.toString().length
                            ? styles.input_placeholder
                            : styles.input_placeholder_not_empty
                    }
                    onClick={() => focusEvent()}
                >
                    {placeholder}
                </p>
                <div>
                    <input
                        autoComplete="off"
                        type={type}
                        name={name}
                        value={value}
                        defaultValue={value}
                        ref={input}
                        onFocus={() => setState(false)}
                        onWheel={(e) => e.currentTarget.blur()}
                        onBlur={(e) => {
                            if (e.target.value === "") {
                                setState(true);
                            } else if (state) {
                                setState(false);
                            }
                        }}
                        onChange={(e) => {
                            if (!e.target.value) {
                                onChange(null)
                            } else {
                                onChange(e);
                            }
                        }}
                    />
                </div>
            </label>
            {error && <span className={errorStyles.validator_text}>{error}</span>}
        </div>
    );
};

export default FpNumberInput;
