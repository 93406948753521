import React, {useState,useContext,createContext} from "react"

export const ShareholderContext = createContext()

export const ShareholderStore = (props) => {
    const [shareholderState, setShareholderState] = useState([])

    return (

        <ShareholderContext.Provider
            value={{
              shareholderState,
              setShareholderState
            }}
        >
            {props.children}
        </ShareholderContext.Provider>
    )
}

const useShareholderStore = () => useContext(ShareholderContext)

export default useShareholderStore