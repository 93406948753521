import React from "react";
import styles from "./FpCheck.module.scss";

const FpCheck = ({
  title,
  type = "checkbox", // or "radio"
  value,
  name, // not used in radio
  onChange = () => {},
  checked,
  className,
  onClick = () => {},
}) => {
  return (
    <label className={`${styles.container} ${className}`}>
      {title}
      <input
        data-checked={checked ? "checked" : "unchecked"}
        type={type}
        value={value}
        checked={checked}
        name={name}
        onChange={(e) => {
          onChange(value, !checked, e.target.checked);
        }}
        onClick={(e) => {
          onClick(e)
      }}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
};

export default FpCheck;
