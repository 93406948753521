import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import FpCheck from "../../UI/FpCheck/FpCheck";
import * as Api from "../../../utils/api";
import {useParams} from "react-router-dom";
import FormValidator from "../../../Helpers/FormValidator";
import useStore from "../../../contexts/context";
import AutoSave from "../../../Helpers/AutoSave";
import {Form} from "react-final-form";
import Input from "../../UI/Input";
import NumberInput from "../../UI/NumberInput";
import {
  ACQUIRING_E_COMMERCE_VALIDATION_RULES,
  ACQUIRING_INITIAL_VALIDATION_RULES, ACQUIRING_OCT_VALIDATION_RULES
} from "../../../validation/formValidation";

const Save = (state) => {
  Api.BackgroundPost("/api/application/acquiring", state)
};

const AquiringInfo = () => {
  let formActualValues = {};
  let {slug} = useParams();
  const [form, setForm] = useState({
    form_url: slug,
    types: [],
    default_currencies: [],
  });
  const [currencies, setCurrencies] = useState([]);
  const [isValidLocation, setIsValidLocation] = useState(false);
  const [data] = Api.Get("/api/application/acquiring/" + slug);
  const {readySubmit, setReadySubmit} = useStore();
  const [isCheckboxNotFiled, setIsCheckboxNotFiled] = useState(false)
  const [isCurrencyNotSelected, setIsCurrencyNotSelected] = useState(false)
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const required = (value) => (value ? undefined : "Required");

  const {acquiringInfo} = useStore();

  const isValidChareholdersLocations = (isTyping) => {
    const cardholders_locations = {
      chlUSA: formActualValues['chlUSA'],
      chlAsia: formActualValues['chlAsia'],
      chlUK: formActualValues['chlUK'],
      chlEU: formActualValues['chlEU'],
      chlCIS: formActualValues['chlCIS']
    };

    const percent = cardholdersLocationReduce(cardholders_locations);
    const isValid = isTyping ? percent <= 100 : percent > 0 && percent <= 100;
    setIsValidLocation(!isValid);

    return isValid;
  };

  const progressBar = (values) => {
    let rulesObj = {};
    Object.keys(values).map((rule) => {
      rulesObj[rule] = "required:string";
    });

    let requiredFields = ACQUIRING_INITIAL_VALIDATION_RULES;

    if (values?.types && values?.types?.includes("E-commerce")) {
      requiredFields = {...requiredFields, ...ACQUIRING_E_COMMERCE_VALIDATION_RULES}
    }

    if (values?.types && values?.types?.includes("OCT/MoneySend")) {
      requiredFields = {...requiredFields, ...ACQUIRING_OCT_VALIDATION_RULES}
    }

    const [, progress] = FormValidator(values, requiredFields);
    const isValid = isValidChareholdersLocations();
    acquiringInfo.setAcquiringPercent(isValid ? progress : 0);
  };

  function formValuesDidChanged (formValues) {
    formActualValues = formValues;
  }

  const activeCurrency = (e) => {
    const {name} = e.target;
    if (name) {
      setIsCurrencyNotSelected(false)
    }
    let res = [...currencies];
    if (res?.includes(name)) {
      res = res.filter((el) => el !== name);
    } else {
      res.push(name);
    }
    setForm({...formActualValues, default_currencies: res})
    setCurrencies(res);
  };

  const checkFormType = (name) => {
    if (name === "E-commerce" || name === "OCT/MoneySend") {
      setIsCheckboxNotFiled(false)
    };

    const formValues = { ...formActualValues };

    if (formActualValues?.types?.length) {
      Object.assign(formValues, { ...formValues, types: [...formValues?.types, name] });
    } else {
      Object.assign(formValues, { ...formValues, types: [name] });
    }
    setForm(formValues);
  };

  const uncheckFormType = (name) => {
    const res = [...formActualValues?.types];
    let typesList = [];
    if (name === "E-commerce") {
      typesList = res.filter((type) => (type !== name && type !== "Recurring"));
    } else {
      typesList = res.filter((type) => (type !== name));
    }
    const formValues = {...formActualValues, types: typesList };
    setForm(formValues);
  };

  const cardholdersLocationReduce = (cardHoldersLocations) => {
    const cardHoldersLocationsClone = { ...cardHoldersLocations };

    if (cardHoldersLocationsClone?.hasOwnProperty("chlOther")) {
      cardHoldersLocationsClone.chlOther = 0
    }
    return Object.values(cardHoldersLocationsClone).reduce(
      (previousValue, currentValue) => +previousValue + +(currentValue ?? ''),
      0
    );
  };

  const handleSubmitValidation = () => {
    if (!form?.types?.includes("OCT/MoneySend") && !form?.types?.includes("E-commerce")) {
      setIsCheckboxNotFiled(true)
    }
    if (!form?.default_currencies || form?.default_currencies?.length === 0) {
      setIsCurrencyNotSelected(true)
    }
    isValidChareholdersLocations()
  }

  const AutoSaver = async (values) => {
    let payload = {};

    payload.cardholders_locations = {
      "chlUSA": values["chlUSA"],
      "chlAsia": values["chlAsia"],
      "chlUK": values["chlUK"],
      "chlEU": values["chlEU"],
      "chlCIS": values["chlCIS"],
      "chlOther": values["chlOther"],
    };

    payload.acquiring_transactions = {
      "minTransAmount": values["minTransAmount"],
      "averageTransAmount": values["averageTransAmount"],
      "maxTransAmount": values["maxTransAmount"],
      "planMonthTurnIn3Month": values["planMonthTurnIn3Month"],
      "planMonthTurnAfter3Month": values["planMonthTurnAfter3Month"],
    };

    payload.requiring = {
      "requiringMinTransAmount": values["requiringMinTransAmount"],
      "requiringAverageTransAmount": values["requiringAverageTransAmount"],
      "requiringMaxTransAmount": values["requiringMaxTransAmount"],
      "requiringPlanMonthTurnBefore3Month": values["requiringPlanMonthTurnBefore3Month"],
      "requiringPlanMonthTurnAfter3Month": values["requiringPlanMonthTurnAfter3Month"],
    };

    payload.oct = {
      "octMinTransAmount": values["octMinTransAmount"],
      "octAverageTransAmount": values["octAverageTransAmount"],
      "octMaxTransAmount": values["octMaxTransAmount"],
      "octPlanMonthTurnBefore3Month": values["octPlanMonthTurnBefore3Month"],
      "octPlanMonthTurnAfter3Month": values["octPlanMonthTurnAfter3Month"],
      "other_currencies": values["other_currencies"],
    };

    isValidChareholdersLocations('typing');

    let currentData = {...values, ...payload}
    progressBar(currentData);
    Save(currentData);
    await sleep(1000);
  };

  const onSubmit = async () => {
    handleSubmitValidation()
    await setReadySubmit({...readySubmit, settlement: true});
  };

  useEffect(() => {
    if (data && data.item && data.item.acquiring) {
      let acquiring = {...form, ...data.item.acquiring};
      acquiring = {
        ...acquiring,
        ...data.item.acquiring.cardholders_locations,
      };
      acquiring = {
        ...acquiring,
        ...data.item.acquiring.acquiring_transactions,
      };
      acquiring = {...acquiring, ...data.item.acquiring.requiring};
      acquiring = {...acquiring, ...data.item.acquiring.oct};
      setForm({...acquiring});
      setCurrencies(data?.item?.acquiring?.default_currencies || currencies);
    }
  }, [data]);

  return (
    <div className={styles.aquiring_info} id="acquiringInfo">
      <Form
        onSubmit={onSubmit}
        initialValues={form}
        subscription={{}}
        className="hide"
      >
        {({handleSubmit}) => (
          <form className={"submittableForm"} onSubmit={handleSubmit}>
            <button
              style={{display: "none"}}
              className={`doSubmit`}
              type="submit"
            >
              Submit
            </button>
            <AutoSave debounce={1000} save={AutoSaver} valuesDidChanged={formValuesDidChanged} />
            <h3 id={"acquiring_info"}>1.3. Acquiring Information</h3>
            <div className="checkbox_component">
              <span className={styles.section_title}>
                Required transaction types *
              </span>
              {isCheckboxNotFiled && <p className={styles.errorText}>Please select at least one type of transaction.</p>}
              <div className={styles.checkbox_container}>
                <FpCheck
                  checked={
                    form && form.types && form.types?.includes("E-commerce")
                  }
                  title={"E-commerce"}
                  value={"E-commerce"}
                  onChange={(value, checked) => {
                    if (checked) {
                      checkFormType(value);
                    } else {
                      uncheckFormType(value);
                    }
                  }}
                />
                {form && form.types && form.types?.includes("E-commerce") &&
                <FpCheck
                  checked={
                    !!(form.types?.includes("E-commerce") && form.types?.includes("Recurring"))
                  }
                  title={"Recurring"}
                  value={"Recurring"}
                  onChange={(value, checked) => {
                    if (checked) {
                      checkFormType(value);
                    } else {
                      uncheckFormType(value);
                    }
                  }}
                />}
                <FpCheck
                  checked={
                    form && form.types && form.types?.includes("OCT/MoneySend")
                  }
                  title={"OCT/MoneySend"}
                  value={"OCT/MoneySend"}
                  onChange={(value, checked) => {
                    if (checked) {
                      checkFormType(value);
                    } else {
                      uncheckFormType(value);
                    }
                  }}
                />
              </div>
            </div>
            {!!form?.types?.length && (
              <React.Fragment>
                <div className={styles.cardholders}>
                  <h4 className="hide">Cardholders locations (% of total) *</h4>
                  <div>
                    {isValidLocation ?
                      <p className={styles.errorText}>
                        At least one of the locations must be filled in and total sum must not exceed 100%
                      </p> : null
                    }
                    <label className="grid_col_5">
                      <NumberInput
                        placeholder="UK"
                        name={`chlUK`}
                      />
                      <NumberInput
                        placeholder="EU"
                        name={`chlEU`}
                      />
                      <NumberInput
                        placeholder="CIS"
                        name={`chlCIS`}
                      />
                      <NumberInput
                        placeholder="USA"
                        name={`chlUSA`}
                      />
                      <NumberInput
                        placeholder="Asia"
                        name={`chlAsia`}
                      />
                    </label>
                  </div>
                </div>
                <Input placeholder="Other (please specify)" name={`chlOther`}/>
                {form.types && form.types?.includes("E-commerce") && (
                  <div className={styles.transactions}>
                    <span className={styles.section_title_margin_top}>
                      Acquiring transactions (EUR) *
                    </span>
                    <div>
                      <label className="grid_col_3">
                        <NumberInput
                          placeholder="Minimum per transaction"
                          name={`minTransAmount`}
                          validate={required}
                        />
                        <NumberInput
                          placeholder="Average per transaction"
                          name={`averageTransAmount`}
                          validate={required}
                        />
                        <NumberInput
                          placeholder="Maximum per transaction"
                          name={`maxTransAmount`}
                          validate={required}
                        />
                        <NumberInput
                          placeholder="Planned monthly turnover in first 3 months"
                          name={`planMonthTurnIn3Month`}
                          validate={required}
                        />
                        <NumberInput
                          placeholder="Planned monthly turnover after 3 months"
                          name={`planMonthTurnAfter3Month`}
                          validate={required}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {form.types && form.types?.includes("Recurring") && form.types?.includes("E-commerce") && (
                  <div className={styles.transactions}>
                    <span className={styles.section_title}>
                      Recurring transactions (EUR)
                    </span>
                    <div>
                      <label className="grid_col_3">
                        <NumberInput
                          name={`requiringMinTransAmount`}
                          placeholder="Minimum per transaction"
                        />
                        <NumberInput
                          name={`requiringAverageTransAmount`}
                          placeholder="Average per transaction"
                        />
                        <NumberInput
                          name={`requiringMaxTransAmount`}
                          placeholder="Maximum per transaction"
                        />
                        <NumberInput
                          name={`requiringPlanMonthTurnBefore3Month`}
                          placeholder="Planned monthly turnover in first 3 months"
                        />
                        <NumberInput
                          name={`requiringPlanMonthTurnAfter3Month`}
                          placeholder="Planned monthly turnover after 3 months"
                        />
                      </label>
                    </div>
                  </div>
                )}
                {form.types && form.types?.includes("OCT/MoneySend") && (
                  <div className={styles.transactions}>
                    <span className={styles.section_title}>
                      OCT/MoneySend transactions (EUR) *
                    </span>
                    <div>
                      <label className="grid_col_3">
                        <NumberInput
                          name={`octMinTransAmount`}
                          placeholder="Minimum per transaction"
                          validate={required}
                        />
                        <NumberInput
                          name={`octAverageTransAmount`}
                          placeholder="Average per transaction"
                          validate={required}
                        />
                        <NumberInput
                          name={`octMaxTransAmount`}
                          placeholder="Maximum per transaction"
                          validate={required}
                        />
                        <NumberInput
                          name={`octPlanMonthTurnBefore3Month`}
                          placeholder="Planned monthly turnover in first 3 months"
                          validate={required}
                        />
                        <NumberInput
                          name={`octPlanMonthTurnAfter3Month`}
                          placeholder="Planned monthly turnover after 3 months"
                          validate={required}
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div className={styles.currencies}>
                  <span className={styles.section_title}>
                    Processing currencies *
                  </span>
                  {isCurrencyNotSelected && <p className={styles.errorText}>Please select at least one processing currencies.</p>}
                  <div>
                    <button
                      name="GBP"
                      className={
                        currencies?.includes("GBP") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="GBP"
                    >
                      GBP <img src="/images/gbp.svg" alt="" name="GBP"/>
                    </button>
                    <button
                      name="EUR"
                      className={
                        currencies?.includes("EUR") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="EUR"
                    >
                      EUR <img src="/images/eur.svg" alt="" name="EUR"/>
                    </button>
                    <button
                      name="DKK"
                      className={
                        currencies?.includes("DKK") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="DKK"
                    >
                      DKK <img src="/images/dkk.svg" alt="" name="DKK"/>
                    </button>
                    <button
                      name="CZK"
                      className={
                        currencies?.includes("CZK") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="CZK"
                    >
                      CZK <img src="/images/czk.svg" alt="" name="CZK"/>
                    </button>
                    <button
                      name="PLN"
                      className={
                        currencies?.includes("PLN") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="PLN"
                    >
                      PLN <img src="/images/pln.svg" alt="" name="PLN"/>
                    </button>
                    <button
                      name="CNY"
                      className={
                        currencies?.includes("CNY") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="CNY"
                    >
                      CNY <img src="/images/cny.svg" alt="" name="CNY"/>
                    </button>
                    <button
                      name="SEK"
                      className={
                        currencies?.includes("SEK") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="SEK"
                    >
                      SEK <img src="/images/sek.svg" alt="" name="SEK"/>
                    </button>
                    <button
                      name="USD"
                      className={
                        currencies?.includes("USD") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="USD"
                    >
                      USD <img src="/images/usd.svg" alt="" name="USD"/>
                    </button>
                    <button
                      name="UAH"
                      className={
                        currencies?.includes("UAH") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="UAH"
                    >
                      UAH <img src="/images/uah.svg" alt="" name="UAH"/>
                    </button>
                    <button
                      name="NOK"
                      className={
                        currencies?.includes("NOK") ? styles.active : ""
                      }
                      onClick={(e) => {
                        activeCurrency(e)
                      }}
                      id="NOK"
                    >
                      NOK <img src="/images/nok.svg" alt="" name="NOK"/>
                    </button>
                  </div>
                </div>
                <Input
                  name={`other_currencies`}
                  placeholder="Additional required processing currencies"
                />
              </React.Fragment>
            )}
            <span className={styles.bottom_line}> </span>
          </form>
        )}
      </Form>
    </div>
  );
};

export default AquiringInfo;
