import React from "react";
import { Field } from "react-final-form";
import FpCountry from "./FpCountry/FpCountry";
import styles from "./style.module.scss";

class Country extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Field {...this.props}>
        {({ input, meta }) => (
          <div>
            <FpCountry
              reactForm={true}
              defaultValue={input.value}
              onChange={input.onChange}
              name={input.name}
              title={this.props.placeholder}
              placeholder={this.props.placeholder}
              setSourceValue={this.props.setSourceValue}
              className={`${this.props.className} ${
                meta.error && meta.touched && styles.validator_error_style
              }`}
            />
            {meta.error && meta.touched && (
              <span className={styles.validator_text}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }
}

export default Country;
